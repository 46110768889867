const PuntuacionIcon = ({ iconWidth, iconHeight }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={iconWidth ?? 30}
            height={iconHeight ?? 30}
            viewBox="0 0 24 24">
            <path fill="#ffffff" d="M7.23 16.5h1v-6h-1v6Zm3.77 0h1v-9h-1v9Zm3.77 0h1v-3h-1v3ZM3.5 20V4h10.923v1H4.5v14h14V9.077h1V20h-16Zm14-12V6h-2V5h2V3h1v2h2v1h-2v2h-1Zm-6 4Z" />
        </svg>
    );
}

export default PuntuacionIcon;