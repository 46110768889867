import { useEffect } from 'react';

const AdsenseMasterPlayerSquare1 = () => {
    useEffect(() => {
        if (window.adsbygoogle && window.adsbygoogle.loaded === false) {
            try {
                (window.adsbygoogle = window.adsbygoogle || []).push({});
            } catch (e) {
                console.error('Adsense error:', e);
            }
        }
    }, []);
    return (
        <ins
            className="adsbygoogle"
            style={{ display: 'block' }}
            data-ad-client="ca-pub-1884623822045383"
            data-ad-slot={2718842960}
            data-ad-format="auto"
            data-full-width-responsive="true"
        ></ins>
    );
};

export default AdsenseMasterPlayerSquare1;
