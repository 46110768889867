import { useReducer, createContext } from 'react';
import { initialState } from './initial-state';
import { app } from './reducers/app';
import { config } from './reducers/config';
import { fixtures } from './reducers/fixtures';
import { user } from './reducers/user';
import { fantasy } from './reducers/fantasy';
import { configuration } from './reducers/configuration';
import { playerProfile } from './reducers/player-profile';
import { preferences } from './reducers/preferences';
import { activities } from './reducers/activites';
import { fantasyHistoric } from './reducers/fantasyHistoric';
import { ads } from './reducers/ads';
import { cacheState } from './reducers/cache-state';

// create context
const Context = createContext({});

// combine reducer function
const combineReducers =
    (...reducers) =>
    (state, action) => {
        for (let i = 0; i < reducers.length; i++) state = reducers[i](state, action);
        return state;
    };

// context provider
const Provider = ({ children }) => {
    const [state, dispatch] = useReducer(
        combineReducers(
            user,
            app,
            fixtures,
            config,
            fantasy,
            configuration,
            playerProfile,
            preferences,
            activities,
            fantasyHistoric,
            ads,
            cacheState
        ),
        initialState
    );
    const value = { state, dispatch };

    return <Context.Provider value={value}>{children}</Context.Provider>;
};

export { Context, Provider };
