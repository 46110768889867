export const adsHelper = {
    addAdsense: function (attempt = 0) {
        const maxAttempts = 5; // Máximo de intentos
        const timeout = attempt * 1000; // Incremento de timeout en cada intento

        setTimeout(() => {
            // Verificar si el script de AdSense está cargado
            if (typeof window.adsbygoogle === 'undefined') {
                console.log('AdSense script not loaded');
                return;
            }

            let ads = document.getElementsByClassName('adsbygoogle');

            let totalAds = 0;
            let adsInitialized = 0;
            for (let i = 0; i < ads.length; i++) {
                if (!ads[i].hasAttribute('data-adsbygoogle-status')) {
                    try {
                        (adsbygoogle = window.adsbygoogle || []).push({});
                        adsInitialized++;
                    } catch (e) {
                        console.log(e);
                    } finally {
                        totalAds++;
                    }
                }
            }

            console.log(
                ads ? `🚀 ${totalAds} ads encontrados - ${adsInitialized} ads inicializados` : 'no ads'
            );

            // Si no se encontraron anuncios, o no se inicializó ninguno y aún hay intentos disponibles
            if (adsInitialized === 0 && attempt < maxAttempts) {
                console.log(`Retrying to load ads... attempt ${attempt + 1}`);
                adsHelper.addAdsense(attempt + 1);
            }
        }, timeout);
    },
};
