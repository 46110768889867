import { Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { Context } from '@/context';
import { FANTASY } from '@/helpers/biwenger-helper';

const CustomTabs = ({ player, handleClick, selected }) => {
    const tabsPerPage = 5;
    const totalTabs = player?.weeks?.length ?? 0;

    const { state } = useContext(Context);
    const { preferences } = state;

    const [currentPage, setCurrentPage] = useState(Math.max(0, Math.ceil(totalTabs / tabsPerPage) - 1));

    const prevPage = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    const nextPage = () => {
        const totalPages = Math.ceil(totalTabs / tabsPerPage);
        if (currentPage < totalPages - 1) {
            setCurrentPage(currentPage + 1);
        }
    };

    const getPorcentaje = (puntos) => {
        if (puntos === 0) {
            return 0;
        }

        const percentage = (puntos * 100) / 35;

        return percentage;
    };

    const getValue = (tabIndex) => {
        if (preferences.fantasy === FANTASY.BIWENGER) {
            const value =
                player?.biwengerStats
                    ?.find((x) => x.week == tabIndex)
                    ?.partido?.points?.find((x) => x.id == preferences.mode)?.points ?? '-';

            return value;
        }

        const value = player?.playerStats?.find((x) => x.weekNumber === tabIndex)?.totalPoints ?? '-';
        return value;
    };

    const renderTabs = () => {
        const start = currentPage * tabsPerPage;
        const end = start + tabsPerPage;
        const tabsToDisplay = player?.weeks?.slice(start, end);

        return tabsToDisplay.map((tabIndex) => (
            <div
                className={`tab-to-display ${tabIndex === selected ? 'tab-to-display-active' : ''}`}
                key={tabIndex}
                style={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '20%',
                    cursor: 'pointer',
                }}
                onClick={() => handleClick(tabIndex)}
            >
                <div>
                    <Typography
                        style={{
                            fontSize: '14px',
                            zIndex: '2',
                            position: 'relative',
                        }}
                        variant="h6"
                        className="tab-to-display-text"
                    >
                        J{tabIndex}
                    </Typography>
                </div>
                <div>
                    <Typography
                        style={{ fontSize: '16px', zIndex: '2', position: 'relative' }}
                        variant="h6"
                        className="tab-to-display-text"
                    >
                        {getValue(tabIndex)}
                    </Typography>
                </div>
                <div className="porcentaje" style={{ height: `${getPorcentaje(getValue(tabIndex))}%` }}></div>
            </div>
        ));
    };

    return (
        <div className="tabs-container">
            <button
                className="arrow-button"
                onClick={prevPage}
                disabled={currentPage === 0}
                style={{ opacity: currentPage === 0 ? 0.3 : 1 }}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                    <path fill="#ffffff" d="M20 15V9H12V4.16L4.16 12 12 19.84V15H20Z" />
                </svg>
            </button>
            <div className="tabs">{renderTabs()}</div>
            <button
                className="arrow-button"
                onClick={nextPage}
                disabled={currentPage === Math.ceil(totalTabs / tabsPerPage) - 1}
                style={{ opacity: currentPage === Math.ceil(totalTabs / tabsPerPage) - 1 ? 0.3 : 1 }}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                    <path fill="#ffffff" d="M4 15V9h8V4.16L19.84 12L12 19.84V15H4Z" />
                </svg>
            </button>
        </div>
    );
};

export default CustomTabs;
