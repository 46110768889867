import { Typography } from '@mui/material';
import AlertSuccessIcon from '../icons/custom-alert/alert-success-icon';
import AlertInfoIcon from '../icons/custom-alert/alert-info-icon';
import AlertErrorIcon from '../icons/custom-alert/alert-error-icon';
import AlertWarningIcon from '../icons/custom-alert/alert-warning-icon';

const CustomAlert = ({
    severity,
    text,
    subText,
    icon,
    onClick,
    hideIcon,
    styles,
    textStyles,
    textStylesContainer,
}) => {
    const getIcon = () => {
        if (hideIcon) return null;
        switch (severity) {
            case 'success':
                return <AlertSuccessIcon />;
            case 'warning':
                return <AlertWarningIcon />;
            case 'info':
                return <AlertInfoIcon />;
            case 'error':
                return <AlertErrorIcon />;
            default:
                return <AlertSuccessIcon />;
        }
    };

    const getBackgroundColor = () => {
        switch (severity) {
            case 'success':
                return '#edf7ed';
            case 'warning':
                return '#fdf3ea';
            case 'error':
                return '#fdeded';
            case 'info':
                return '#e5f6fd';
            default:
                return '#ccc';
        }
    };

    const handleClick = () => {
        if (onClick) {
            onClick();
        }
    };

    return (
        <div
            className="custom-alert-container"
            style={{ ...styles, backgroundColor: getBackgroundColor(), marginBottom: '15px' }}
        >
            <div>{icon ?? getIcon()}</div>
            <div style={{ ...textStylesContainer }}>
                <Typography style={{ ...textStyles }}>{text}</Typography>
                <div>
                    <small
                        style={{ color: onClick && '#0070f3', cursor: onClick && 'pointer' }}
                        onClick={handleClick}
                    >
                        {subText}
                    </small>
                </div>
            </div>
        </div>
    );
};

export default CustomAlert;
