import { Fragment, useContext, useEffect, useState } from 'react';
import { Drawer, Typography, Box, Button } from '@mui/material';
import { Line } from 'react-chartjs-2';
import MasterPlayerHeaderComponent from './master-player-header';
import MasterPlayerCardComponent from './master-player-card';
import { Context } from '@/context';
import axiosApiInstance from '@/api/shared/axios-instance';
import URL_FACTORY from '@/helpers/url-helper';
import { REDUCERS } from '@/constants/reducers';
import { EVENTS_ACTIONS, EVENTS_CATEGORIES, EVENTS_LABELS, logEventTracker } from '@/helpers/events-helper';
import { toPriceInEuros } from '@/helpers/prices-helper';
import { formatDateToDDMMYYYY } from '@/helpers/datetime-helper';

const FantasyHistoricModalComponent = () => {
    const [open, setOpen] = useState(false);
    const [player, setPlayer] = useState(null);
    const [filter, setFilter] = useState('Temporada');
    const { state, dispatch } = useContext(Context);
    const { fantasyHistoric } = state;
    const [cache, setCache] = useState([]);
    const [anchor] = useState('right');

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (fantasyHistoric?.fantasyId > 0) {
            const isCached = cache?.find((x) => x.fantasyId == fantasyHistoric?.fantasyId);

            if (isCached) {
                setOpen(true);
                setPlayer(isCached.data);
                logEventTracker(EVENTS_ACTIONS.FANTASY_HISTORIC, EVENTS_CATEGORIES.OPEN, EVENTS_LABELS.CACHE);
                return;
            }

            const url = URL_FACTORY.GetHistoricFantasy;

            const payload = {
                fantasyId: fantasyHistoric?.fantasyId,
                season: fantasyHistoric?.season,
            };

            dispatch({ type: REDUCERS.SHOW_SPINNER });

            logEventTracker(EVENTS_ACTIONS.FANTASY_HISTORIC, EVENTS_CATEGORIES.OPEN, EVENTS_LABELS.API);

            axiosApiInstance
                .post(url, payload)
                .then((response) => {
                    if (response?.data?.information) {
                        setOpen(true);
                        setPlayer(response?.data);

                        // Add response to cache
                        setCache((prevCache) => [
                            ...prevCache,
                            {
                                fantasyId: response?.data.fantasyPlayer?.information?.id,
                                playerId: response?.data.information?.id,
                                data: response?.data,
                            },
                        ]);
                    }
                })
                .finally(() => {
                    dispatch({ type: REDUCERS.HIDE_SPINNER });
                });
        } else {
            setOpen(false);
        }
    }, [fantasyHistoric]);

    const getFilteredMarketValues = () => {
        if (!player) return [];

        let marketValues = player.marketValues;

        if (filter === '30 días') {
            marketValues = marketValues.sort((a, b) => b.day - a.day).slice(0, 30);
        } else if (filter === '7 días') {
            marketValues = marketValues.sort((a, b) => b.day - a.day).slice(0, 7);
        }

        return marketValues?.sort((a, b) => a.day - b.day);
    };

    const renderChart = () => {
        const filteredMarketValues = getFilteredMarketValues();

        const data = {
            labels: filteredMarketValues.map((value) => value.created),
            datasets: [
                {
                    label: 'Market Value',
                    data: filteredMarketValues.map((value) => value.marketValue),
                    fill: false,
                    backgroundColor: 'rgba(75,192,192,1)',
                    borderColor: 'rgba(75,192,192,1)',
                },
            ],
        };

        return <Line data={data} />;
    };

    const renderLabels = () => {
        const filteredMarketValues = getFilteredMarketValues();
        const marketValues = filteredMarketValues.map((value) => value.marketValue);

        const maxMarketValue = Math.max(...marketValues);
        const minMarketValue = Math.min(...marketValues);
        const maxMarketValueDate = formatDateToDDMMYYYY(
            filteredMarketValues.find((value) => value.marketValue === maxMarketValue).created
        );
        const minMarketValueDate = formatDateToDDMMYYYY(
            filteredMarketValues.find((value) => value.marketValue === minMarketValue).created
        );

        // Calculando las variaciones diarias
        const dailyChanges = marketValues
            .map((value, index, array) => {
                if (index === 0) return 0;
                return value - array[index - 1];
            })
            .slice(1);

        const maxDailyChange = Math.max(...dailyChanges);
        const minDailyChange = Math.min(...dailyChanges);
        const maxDailyChangeIndex = dailyChanges.indexOf(maxDailyChange);
        const minDailyChangeIndex = dailyChanges.indexOf(minDailyChange);

        const maxDailyChangeDate = formatDateToDDMMYYYY(
            filteredMarketValues[maxDailyChangeIndex + 1].created
        );
        const minDailyChangeDate = formatDateToDDMMYYYY(
            filteredMarketValues[minDailyChangeIndex + 1].created
        );

        return (
            <Fragment>
                <Box sx={{ display: 'flex', justifyContent: 'space-around', marginTop: '16px' }}>
                    <Box
                        sx={{
                            textAlign: 'center',
                            backgroundColor: '#50b47d',
                            color: '#fff',
                            padding: '8px',
                            borderRadius: '4px',
                            width: '45%',
                        }}
                    >
                        <Typography variant="subtitle2">Precio máximo</Typography>
                        <Typography variant="h6">{toPriceInEuros(maxMarketValue)}</Typography>
                        <Typography variant="body2">{maxMarketValueDate}</Typography>
                    </Box>
                    <Box
                        sx={{
                            textAlign: 'center',
                            backgroundColor: '#da918c',
                            color: '#fff',
                            padding: '8px',
                            borderRadius: '4px',
                            width: '45%',
                        }}
                    >
                        <Typography variant="subtitle2">Precio mínimo</Typography>
                        <Typography variant="h6">{toPriceInEuros(minMarketValue)}</Typography>
                        <Typography variant="body2">{minMarketValueDate}</Typography>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-around', marginTop: '16px' }}>
                    <Box
                        sx={{
                            textAlign: 'center',
                            backgroundColor: '#50b47d',
                            color: '#fff',
                            padding: '8px',
                            borderRadius: '4px',
                            width: '45%',
                        }}
                    >
                        <Typography variant="subtitle2">Mayor subida</Typography>
                        <Typography variant="h6">
                            {maxDailyChange > 0 ? '+' + toPriceInEuros(maxDailyChange) : '-'}
                        </Typography>
                        <Typography variant="body2">{maxDailyChangeDate}</Typography>
                    </Box>
                    <Box
                        sx={{
                            textAlign: 'center',
                            backgroundColor: '#da918c',
                            color: '#fff',
                            padding: '8px',
                            borderRadius: '4px',
                            width: '45%',
                        }}
                    >
                        <Typography variant="subtitle2">Mayor bajada</Typography>
                        <Typography variant="h6">
                            {minDailyChange < 0 ? toPriceInEuros(minDailyChange) : '-'}
                        </Typography>
                        <Typography variant="body2">{minDailyChangeDate}</Typography>
                    </Box>
                </Box>
            </Fragment>
        );
    };

    const renderFilters = () => (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
            {['Temporada', '30 días', '7 días'].map((f) => (
                <Button
                    key={f}
                    variant={filter === f ? 'contained' : 'outlined'}
                    onClick={() => setFilter(f)}
                    sx={{
                        margin: '0 8px',
                    }}
                >
                    {f}
                </Button>
            ))}
        </Box>
    );

    const renderModal = () => {
        if (!player || !open) return null;
        return (
            <Drawer
                anchor={anchor}
                open={open}
                onClose={handleClose}
                className="master-player-modal"
                transitionDuration={300}
            >
                <div className="historic-header">
                    <MasterPlayerHeaderComponent
                        player={player}
                        handleClose={() =>
                            dispatch({
                                type: REDUCERS.HIDE_FANTASY_HISTORIC,
                            })
                        }
                    />
                    <MasterPlayerCardComponent
                        player={player}
                        hideFantasySelector={true}
                        hideNextRival={true}
                    />
                </div>
                <div style={{ padding: '16px' }}>
                    <Typography style={{ textAlign: 'center', fontSize: '16px', marginBottom: '16px' }}>
                        Valores de mercado de {player?.information?.name} 2023/2024
                    </Typography>
                    {renderFilters()}
                    {renderChart()}
                    {renderLabels()}
                </div>
            </Drawer>
        );
    };

    return <Fragment>{renderModal()}</Fragment>;
};

export default FantasyHistoricModalComponent;
