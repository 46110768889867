import { getMasterPlayerImage } from './images-helper';
import URL_FACTORY from './url-helper';
import { POSITION_COLORS, POSITION_NAMES } from '@/constants/color-constants';
import { PLAYER_STATUS } from '@/constants/player-constants';
import TipsIcon from '@/components/shared/icons/tips-icon';
import InfoIcon from '@/components/shared/icons/info-icon';
import InjuredStatusIcon from '@/components/shared/icons/status-icons/injured-status-icon';
import SancionadosIcon from '@/components/shared/icons/sancionados-icon';
import AvailableStatusIcon from '@/components/shared/icons/status-icons/available-status-icon';
import ExtradeportivoIcon from '@/components/shared/icons/status-icons/extradeportivo-icon';
// import CapitanArieteIcon from '@/components/shared/icons/recommendation-icons/capitan-ariete-icon';
// import CapitanIcon from '@/components/shared/icons/recommendation-icons/capitan-icon';
// import ArieteIcon from '@/components/shared/icons/recommendation-icons/ariete-icon';
// import RevulsivoIcon from '@/components/shared/icons/recommendation-icons/revulsivo-icon';
// import BufonIcon from '@/components/shared/icons/recommendation-icons/bufon-icon';
// import ChollosIcon from '@/components/shared/icons/recommendation-icons/chollos-icon';
import ConvocadoIcon from '@/components/shared/icons/convocado-icon';
import CheckIcon from '@/components/shared/icons/check-icon';
import ApercibidosStatusIcon from '@/components/shared/icons/apercibidos-status-icon';
import DudaStatusIcon from '@/components/shared/icons/status-icons/duda-status-icon';
import DudaLeveStatusIcon from '@/components/shared/icons/status-icons/duda-leve-status-icon';
import NoInscritoIcon from '@/components/shared/icons/recommendation-icons/no-inscrito-icon';

export const getCssClassForMarketValueDifference = (marketValue, nextMarketValue) => {
    if (!nextMarketValue) {
        return 'black-diff';
    }
    return marketValue - nextMarketValue > 0 ? 'green-diff' : 'red-diff';
};

export const getMarketValueDifference = (marketValue, nextMarketValue) => {
    if (!nextMarketValue) {
        return '-';
    }
    return (marketValue - nextMarketValue).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' €';
};

export const getDifferenceColor = function (number) {
    if (number >= 0) {
        return 'green';
    }

    return 'red';
};

export const replaceDiacritis = (text) => {
    if (!text) return text;
    const diacriticsMap = {
        à: 'a',
        á: 'a',
        â: 'a',
        ã: 'a',
        ä: 'a',
        å: 'a',
        æ: 'ae',
        ç: 'c',
        č: 'c',
        ć: 'c',
        è: 'e',
        é: 'e',
        ê: 'e',
        ë: 'e',
        ì: 'i',
        í: 'i',
        î: 'i',
        ï: 'i',
        ð: 'd',
        ò: 'o',
        ó: 'o',
        ô: 'o',
        õ: 'o',
        ö: 'o',
        ø: 'o',
        ù: 'u',
        ú: 'u',
        û: 'u',
        ü: 'u',
        ý: 'y',
        þ: 'th',
        ÿ: 'y',
        ć: 'c',
    };

    return text
        .replace(/[àáâãäåæçèéêëìíîïðòóôõöøùúûüýþÿ]/g, (match) => {
            return diacriticsMap[match];
        })
        .toLowerCase();
};

export const getPlayerPositionColor = function (positionId) {
    switch (positionId) {
        case -1:
            return '';
        case 1:
            return 'po';
        case 2:
            return 'df';
        case 3:
            return 'mc';
        case 5:
            return 'dt';
        case 11:
            return 'to';
        default:
            return 'dl';
    }
};

export const getPlayerPositionShort = function (positionId) {
    switch (positionId) {
        case -1:
            return '';
        case 1:
            return 'PO';
        case 2:
            return 'DF';
        case 3:
            return 'MC';
        case 5:
            return 'DT';
        case 11:
            return 'TO';
        default:
            return 'DL';
    }
};

export const getPlayerPosition = (positionId) => {
    switch (positionId) {
        case 1:
            return 'Portero';
        case 2:
            return 'Defensa';
        case 3:
            return 'Medio';
        case 5:
            return 'Entrenador';
        default:
            return 'Delantero';
    }
};

export const getPlayerStatsColor = (playerStatus) => {
    switch (playerStatus) {
        case 'Lesionado':
            return 'red';
        case 'Sancionado':
            return 'orange';
        case 'Duda':
            return 'yellow';
        case 'Disponible':
        default:
            return 'green';
    }
};

export const getPlayerStatusList = () => {
    return [
        { label: 'Disponible', value: PLAYER_STATUS.OK },
        { label: 'Lesionado', value: PLAYER_STATUS.INJURED },
        { label: 'Sancionado', value: PLAYER_STATUS.SANCTIONED },
        { label: 'Duda', value: PLAYER_STATUS.DOUBTFUL },
    ];
};

export const getPlayerStatusLevelList = (status) => {
    if (status === PLAYER_STATUS.INJURED) {
        return [
            { label: 'Cruz Roja', value: '1' },
            { label: 'Cruz Negra', value: '2' },
            { label: 'Extradeportivo', value: '3' },
        ];
    } else if (status === PLAYER_STATUS.DOUBTFUL) {
        return [
            { label: 'Cruz Verde', value: '1' },
            { label: 'Cruz Naranja', value: '2' },
        ];
    }

    return [{ label: 'No aplica', value: '1' }];
};

export const getPlayerColor = (positionId) => {
    switch (positionId) {
        case POSITION_NAMES.PORTERO:
            return POSITION_COLORS.PORTERO;
        case POSITION_NAMES.DEFENSA:
            return POSITION_COLORS.DEFENSA;
        case POSITION_NAMES.MEDIOCAMPISTA:
            return POSITION_COLORS.MEDIOCAMPISTA;
        case POSITION_NAMES.DELANTERO:
            return POSITION_COLORS.DELANTERO;
        case POSITION_NAMES.ENTRENADOR:
            return POSITION_COLORS.ENTRENADOR;
        default:
            return '';
    }
};

export const getPlayerScoreRounded = (score) => {
    if (!score) {
        return '-';
    }
    return score.toFixed(2);
};

export const getScoreColor = (score, isAvailable, isTransparent) => {
    if (isTransparent) {
        return '#007252';
    }

    if (score === null || score === undefined || !isAvailable) {
        return 'transparent';
    }

    const scoreNumber = Number(score);

    if (scoreNumber >= 8) {
        return '#1d6fb1';
    } else if (scoreNumber >= 5) {
        return '#1a892d';
    } else if (scoreNumber >= 3) {
        return '#103b17';
    } else if (scoreNumber >= 0) {
        return 'rgb(216, 136, 17)';
    } else {
        // Lower than 0
        return '#be1212';
    }
};

export const getMinutesColor = (score, isAvailable) => {
    if (score === null || score === undefined || !isAvailable) {
        return '#ccc';
    }

    const scoreNumber = Number(score);

    if (scoreNumber >= 90) {
        return '#1d6fb1';
    } else if (scoreNumber >= 60) {
        return '#1a892d';
    } else if (scoreNumber >= 1) {
        return '#103b17';
    } else if (scoreNumber == 0) {
        return '#8d5f09';
    } else {
        // Lower than 0
        return '#be1212';
    }
};

export const getAvailableStatus = (status) => {
    switch (status) {
        case PLAYER_STATUS.OK:
            return 'Sí';
        case PLAYER_STATUS.DOUBTFUL:
            return 'Duda';
        case PLAYER_STATUS.INJURED:
        case PLAYER_STATUS.SUSPENDED:
            return 'No';
        default:
            return '';
    }
};

export const getAvailableStatusColor = (status) => {
    switch (status) {
        case PLAYER_STATUS.OK:
            return 'rgb(1, 170, 77)';
        case PLAYER_STATUS.DOUBTFUL:
            return 'rgb(237, 108, 2)';
        case PLAYER_STATUS.INJURED:
        case PLAYER_STATUS.SUSPENDED:
            return 'rgb(211, 47, 47)';
        default:
            return '';
    }
};

export const getPlayerName = (player) => {
    if (player?.information?.name ?? false) {
        return player.information.name;
    }

    if (player?.information?.nickname ?? false) {
        return player.information.nickname;
    }

    if (player?.fantasyPlayer?.information?.nickname ?? false) {
        return player.fantasyPlayer.information.nickname;
    }

    return player?.information?.nickname;
};

export const getPlayerPositionId = (player) => {
    return player?.information.positionId;
};

export const getPlayerTeamId = (player) => {
    return player?.information.teamId ?? player.teamId;
};

export const getPlayerImage = (player, isFantasy, size, width) => {
    if (player?.information?.photo) {
        return URL_FACTORY.GetUniquePlayerImage(player?.information?.photo, width);
    }
    if (player?.fantasyPlayer) {
        const id = player?.fantasyPlayer?.information?.id ?? player?.information?.id;
        return id ? getFantasyPlayerImage(id, width) : URL_FACTORY.GetPlayerTemplateImage();
    }

    if (player?.information?.nickname) {
        if (player?.information?.photo) {
            return player?.information?.photo;
        }

        return getMasterPlayerImage(player, width);
    }

    return URL_FACTORY.GetPlayerTemplateImage();
};

export const getPlayersStatus = (status, statusLevel) => {
    switch (status) {
        case PLAYER_STATUS.INJURED:
            switch (statusLevel) {
                case 3:
                    return 'Extradeportivo';
                case 1:
                    return 'Lesionado';
                case 2:
                    return 'Lesionado';
                default:
                    return 'Lesionado';
            }
        case PLAYER_STATUS.DOUBTFUL:
            switch (statusLevel) {
                case 2:
                    return 'Duda';
                case 1:
                default:
                    return 'Duda leve';
            }

        case PLAYER_STATUS.SANCTIONED:
            return 'Sancionado';
        case PLAYER_STATUS.CONVOCADO:
            return 'Convocado';
        case PLAYER_STATUS.NO_CONVOCADO:
            return 'Disponible';
        case PLAYER_STATUS.OK:
        default:
            return 'Disponible';
    }
};

export const getPlayerIcon = (playerStatus, statusLevel, iconWidth, iconHeight) => {
    if (!playerStatus || !isNaN(playerStatus)) return '';
    switch (playerStatus?.toLowerCase()) {
        case PLAYER_STATUS.INJURED:
            switch (statusLevel) {
                case 2:
                case '2':
                    return (
                        <InjuredStatusIcon
                            iconHeight={iconWidth ?? 16}
                            iconWidth={iconHeight ?? 16}
                            color="#22272F"
                        />
                    );
                case 3:
                case '3':
                    return <ExtradeportivoIcon iconHeight={iconWidth ?? 18} iconWidth={iconHeight ?? 18} />;
                case 1:
                case '1':
                default:
                    return (
                        <InjuredStatusIcon
                            iconHeight={iconWidth ?? 16}
                            iconWidth={iconHeight ?? 16}
                            color="#e11d48"
                        />
                    );
            }
        case PLAYER_STATUS.SANCTIONED:
            return <SancionadosIcon iconHeight={18} iconWidth={16} />;
        case PLAYER_STATUS.DOUBTFUL:
            switch (statusLevel) {
                case 2:
                case '2':
                    return (
                        <DudaStatusIcon
                            iconHeight={iconWidth ?? 16}
                            iconWidth={iconHeight ?? 16}
                            color="#ea580c"
                        />
                    );
                case 1:
                case '1':
                default:
                    return (
                        <DudaLeveStatusIcon
                            iconHeight={iconWidth ?? 16}
                            iconWidth={iconHeight ?? 16}
                            color="#059669"
                        />
                    );
            }
        case PLAYER_STATUS.CONVOCADO:
            return <ConvocadoIcon height={20} width={20} />;
        case PLAYER_STATUS.NO_CONVOCADO:
            return <CheckIcon height={20} width={20} />;
        case PLAYER_STATUS.OK:
        default:
            return <AvailableStatusIcon />;
    }
};

export const getReport = (p) => {
    if (p?.generalStatus?.report) {
        let report = p.generalStatus.report;

        if (report.length > 2) {
            report = report.charAt(0).toUpperCase() + report.slice(1);
        }

        return (
            <div className="player-information-container__item-info-status">
                <TipsIcon styles={{ fontSize: '16px', color: '#01aa4d' }} />
                {report}
            </div>
        );
    } else {
        return (
            <div className="player-information-container__item-info-status">
                <TipsIcon styles={{ fontSize: '16px', color: '#01aa4d', visibility: 'hidden' }} />
            </div>
        );
    }
};

export const getStatusInfo = (p, width, height) => {
    if (p?.generalStatus?.status === PLAYER_STATUS.OK) {
        return (
            <div className="player-information-container__item-info-status">
                <InfoIcon style={{ fontSize: '16px', color: '#1976d2' }} />
                {p?.generalStatus?.status === PLAYER_STATUS.OK && 'Disponible para competir'}
            </div>
        );
    }

    if (p?.generalStatus?.statusInfo) {
        let statusInfo = p.generalStatus.statusInfo;

        if (statusInfo.length > 2) {
            statusInfo = statusInfo.charAt(0).toUpperCase() + statusInfo.slice(1);
        }

        return (
            <div className="player-information-container__item-info-status">
                <div style={{ width: '15px' }}>
                    <InfoIcon iconWidth={width} iconHeight={height} />
                </div>
                <div>{statusInfo}</div>
            </div>
        );
    }
};

export const getRecommendationsIcons = (player, alternatives) => {
    if (!player) return null;

    if (player?.isNoInscrito) {
        return (
            <div
                className={`lineup-selector-container__recomendaciones ${
                    alternatives ? 'lineup-selector-container__recomendaciones-alternatives' : ''
                }`}
            >
                <NoInscritoIcon />
            </div>
        );
    }
};

export const getFillColor = (semaforo) => {
    if (semaforo === 1) {
        return '#78b159';
    } else if (semaforo === 2) {
        return '#fdcb58';
    } else if (semaforo === 3) {
        return '#dd2e44';
    } else {
        return '#fff';
    }
};

export const getIsApercibidoIcon = (player) => {
    if (player?.generalStatus?.isApercibido) {
        return (
            <div style={{ position: 'absolute', top: '54px', left: '5px' }}>
                <ApercibidosStatusIcon iconWidth={15} iconHeight={15} />
            </div>
        );
    }
};
