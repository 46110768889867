import { useRouter } from 'next/router';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Fragment, useContext, useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import MasterPlayerModalComponent from '../master-player-modal';
import FantasyHistoricModalComponent from '../master-player-modal/fantasy-historic-modal';
import PwaBanner from '../add-to-home-screen/pwa-banner';
import AdsenseFooterHorizontal1 from '../ads-components/footer/adsense-footer.horizontal-1';
import FooterSection from './footer';
import DesktopNavigationComponent from './desktop-navigation';
import TeamLogoComponent from './team-logo';
import LeftDrawerComponent from './left-drawer-component';
import LoadingSpinner from './custom/spinner-loading';
import MobileNavigationComponent from './mobile-navigation';
import RightDrawerComponent from './right-drawer-component';
import ActividadBanner from './actividad-banner';
import TwitchLive from './twitch-live';
import SocialComponent from './social';
import JugadoresNavigationComponent from './jugadores-navigation-component';
import { Context } from '@/context';
import { REDUCERS } from '@/constants/reducers';
import axiosApiInstance from '@/api/shared/axios-instance';
import URL_FACTORY from '@/helpers/url-helper';
import { Breakpoints, SITE_USER_COOKIE } from '@/constants/site';
import { SUBSCRIPTION_TYPE } from '@/constants/subscription-type';
import { getFromLocalStorage } from '@/helpers/cookies';
import { adsHelper } from '@/helpers/ads-helper';

const theme = createTheme({
    palette: {
        primary: {
            main: '#1976d2',
        },
        secondary: {
            main: '#01aa4d',
        },
        disabled: {
            main: '#9e9e9e',
        },
        error: {
            main: '#d32f2f',
        },
        warning: {
            main: '#ed6c02',
        },
        delete: {
            main: '#fff',
            contrastText: '#d32f2f',
        },
        link: {
            main: '#0d6efd',
        },
        analitica: {
            main: '#22272F',
        },
        tabs: {
            main: '#22272F',
        },
        white: {
            main: '#fff',
        },
        tabsDecorator: {
            main: '#1976d2',
        },
        white: {
            main: '#fff',
        },
        risks: {
            veryHigh: 'rgb(242 127 127)',
            high: 'rgb(208 172 108)',
            medium: '#dddddd',
            low: 'rgb(148 191 148)',
            veryLow: 'rgb(192 216 237)',
        },
        minutes: {
            veryHigh: '#2196f3',
            high: '#0C6',
            medium: '#ccc',
            low: '#F90',
            veryLow: '#d32f2f',
        },
        competitions: {
            laLiga: '#ff4b44',
            fifaFriendlies: '#4db7ad',
            championsLeague: '#1d6fb1',
            supercopaEspaña: '#F66A03',
            copaRey: 'rgb(229 184 105)',
            europaLeague: '#1a892d',
            euroClasificacion: '#158ec0',
            defaultColor: '#69b59f',
        },
    },
    typography: {
        allVariants: {
            fontFamily: `'Roboto Condensed', sans-serif`,
            textTransform: 'none',
            fontSize: 16,
        },
    },
});

const Layout = (props) => {
    const { state, dispatch } = useContext(Context);
    const { app, user, activities } = state;
    const [rightDrawer, setRightDrawer] = useState(false);
    const [leftDrawer, setLeftDrawer] = useState(false);
    // const isBigScreen = useMediaQuery('(min-width:1400px)');
    // const isMobile = useMediaQuery('(max-width:576px)');
    const router = useRouter();

    useEffect(() => {
        dispatch({ type: REDUCERS.UPDATE_USER, payload: { currentUrl: router?.asPath } });
    }, [router?.asPath]);

    useEffect(() => {
        adsHelper.addAdsense();
    }, [router?.asPath]);

    useEffect(() => {
        // Add adsense after 4 seconds for the first time user enters the website
        setTimeout(() => {
            adsHelper.addAdsense();
        }, 1000);
    }, []);

    const handleCloseLeftDrawer = () => {
        setLeftDrawer(false);
    };

    const handleCloseRightDrawer = () => {
        setRightDrawer(false);
    };

    const handleHamburguerMenu = () => {
        setRightDrawer(false);
        setLeftDrawer(!leftDrawer);
    };

    const handlePartidosMenu = () => {
        setRightDrawer(!rightDrawer);
        setLeftDrawer(false);
    };

    const getFixturesDrawer = () => {
        axiosApiInstance
            .get(URL_FACTORY.GET_URL_RecentFixtures)
            .then((response) => {
                dispatch({
                    type: REDUCERS.UPDATE_FIXTURES_ROUND,
                    payload: {
                        rounds: response.data.rounds,
                        activeRoundIndex: response.data.activeIndex,
                    },
                });
            })
            .catch(() => {});
    };

    const getNavigationMenu = () => {
        if (app.mobile) {
            return (
                <MobileNavigationComponent
                    logged={user.logged}
                    isAdmin={user.isAdmin}
                    handleHamburguerMenu={handleHamburguerMenu}
                    handlePartidosMenu={handlePartidosMenu}
                />
            );
        } else {
            return (
                <DesktopNavigationComponent
                    logged={user.logged}
                    isAdmin={user.isAdmin}
                    handlePartidosMenu={handlePartidosMenu}
                    handleHamburguerMenu={handleHamburguerMenu}
                />
            );
        }
    };

    const getLeftDrawer = () => {
        return <LeftDrawerComponent anchor="left" open={leftDrawer} handleClose={handleCloseLeftDrawer} />;
    };

    const getRightDrawer = () => {
        return (
            <RightDrawerComponent
                anchor="right"
                open={rightDrawer}
                handleClose={handleCloseRightDrawer}
                handlePartidosMenu={handlePartidosMenu}
                logged={app.logged}
            />
        );
    };

    // const getLeftFixedAds = () => {
    //     if (!isBigScreen) {
    //         return null;
    //     }

    //     return (
    //         <div
    //             className="v-hidden-sideads lazy-ad-unit"
    //             style={{
    //                 width: '300px',
    //                 height: '600px',
    //                 position: 'fixed',
    //                 top: '205px',
    //                 right: '51%',
    //                 transform: 'translate(-600px)',
    //                 display: 'flex',
    //                 justifyContent: 'flex-end',
    //                 alignItems: 'center',
    //             }}
    //         >
    //             <YL_300x600_1 />
    //             {/* <AdsenseResponsive unit={AdsenseResponsiveUnit.vertical_1} /> */}
    //         </div>
    //     );
    // };

    // const getRightFixedAds = () => {
    //     if (!isBigScreen) {
    //         return null;
    //     }

    //     return (
    //         <div
    //             className="v-hidden-sideads lazy-ad-unit"
    //             style={{
    //                 width: '300px',
    //                 height: '600px',
    //                 position: 'fixed',
    //                 top: '205px',
    //                 right: '49%',
    //                 transform: 'translate(900px)',
    //                 display: 'flex',
    //                 justifyContent: 'flex-start',
    //                 alignItems: 'center',
    //             }}
    //         >
    //             <YL_300x600_2 />
    //         </div>
    //     );
    // };

    useEffect(() => {
        getFixturesDrawer();
        // setTimeout(() => {
        // }, 500);

        // // fetch fixtures every 5 minutes
        // setInterval(() => {
        //     getFixturesDrawer();
        // }, 1000 * 60 * 5);
    }, []);

    useEffect(() => {
        dispatch({ type: REDUCERS.GET_DATA_FROM_COOKIES });
    }, [user?.subscriptionType]);

    useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }

        let app = { ...state.app };
        app.width = window.innerWidth;
        app.height = window.innerHeight;

        if (window.innerWidth < Breakpoints.XXL) {
            app.mobile = true;
        } else {
            app.mobile = false;
        }

        dispatch({ type: REDUCERS.UPDATE_APP, payload: app });

        const desktopMediaQuery = window.matchMedia(`(min-width: ${Breakpoints.XXL}px)`);

        desktopMediaQuery.addEventListener('change', (e) => {
            let app = { ...state.app };

            if (e.matches) {
                app.mobile = false;
            } else {
                app.mobile = true;
            }

            dispatch({ type: REDUCERS.UPDATE_APP, payload: app });
        });
    }, []);

    const getActivitiesData = () => {
        axiosApiInstance
            .post(URL_FACTORY.GetActivities, { skip: activities.skip, take: activities.take })
            .then((response) => {
                dispatch({
                    type: REDUCERS.UPDATE_ACTIVITIES,
                    payload: {
                        ...activities,
                        activities: [...activities.activities, ...response.data.activities],
                    },
                });
            });
    };

    const FETCH_ACTIVITIES_SECONDS = 300; // Reload activities every 5 minutes

    useEffect(() => {
        getActivitiesData();
        const intervalId = setInterval(() => {
            getActivitiesData();
        }, FETCH_ACTIVITIES_SECONDS * 1000);
        return () => clearInterval(intervalId);
    }, [activities.skip]);

    useEffect(() => {
        const isProduction = process.env.NEXT_PUBLIC_ENV_VARIABLE !== 'development';

        if (!isProduction) {
            return;
        }

        const dataFromCookies = getFromLocalStorage(SITE_USER_COOKIE);
        const data = JSON.parse(dataFromCookies);

        if (data && data.subscriptionType !== SUBSCRIPTION_TYPE.FREE) {
            return;
        }

        // Obtén el contenedor de scripts por su ID
        const adsScriptsContainer = document.getElementById('ads-scripts');

        if (adsScriptsContainer) {
            // Script de videoo.tv (sustituye el de AdSense)
            const adsenseScript = document.createElement('script');
            adsenseScript.async = true;
            adsenseScript.src =
                'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1884623822045383';
            adsenseScript.crossOrigin = 'anonymous';
            adsenseScript.setAttribute('data-overlays', 'bottom');

            // Añadir los scripts al contenedor
            adsScriptsContainer.appendChild(adsenseScript);

            setTimeout(() => {
                adsHelper.addAdsense();
            }, 100);

            // Actualizar estado de la aplicación
            dispatch({
                type: REDUCERS.UPDATE_APP,
                payload: {
                    ads: true,
                },
            });
        }
    }, []);

    const getCurrentUrl = (url) => {
        return url?.split('/')?.[1] ?? '';
    };

    return (
        <Fragment>
            <ThemeProvider theme={theme}>
                <SocialComponent />

                {getNavigationMenu()}
                {getLeftDrawer()}

                {getRightDrawer()}

                <section
                    className={`${
                        user?.subscriptionType !== SUBSCRIPTION_TYPE.FREE
                            ? 'analitica-premium'
                            : 'analitica-free'
                    } main-content url-${getCurrentUrl(router.asPath)}`}
                >
                    <TeamLogoComponent />
                    <JugadoresNavigationComponent />
                    {/* {getLeftFixedAds()} */}

                    {/* {getRightFixedAds()} */}
                    <div className="container">
                        <TwitchLive />
                        <ActividadBanner />
                        {/* {getHeaderAds()} */}
                    </div>
                    <main className="main-website">{props.children}</main>

                    <div className="container">
                        <AdsenseFooterHorizontal1 />
                    </div>

                    <FooterSection />
                </section>

                <ToastContainer />

                <LoadingSpinner isLoading={app.isSpinnerVisible}></LoadingSpinner>

                <PwaBanner />

                <MasterPlayerModalComponent />
                <FantasyHistoricModalComponent />

                <div id="ads-scripts"></div>
            </ThemeProvider>
        </Fragment>
    );
};

export default Layout;
