import { useContext, useState } from 'react';
import { useRouter } from 'next/router';
import { Box, Paper, useTheme } from '@mui/material';
import { autoPlay } from 'react-swipeable-views-utils';
import SwipeableViews from 'react-swipeable-views';
import { getIcon } from '../home/home-activities';
import CustomAlert from './custom/custom-alert';
import { Rutas } from '@/constants/rutas';
import { Context } from '@/context';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const ActividadBanner = () => {
    const { state } = useContext(Context);
    const { activities } = state;
    const router = useRouter();
    const theme = useTheme();
    const [activeStep, setActiveStep] = useState(0);

    const lastTenActivities = activities?.activities?.slice(0, 10) || [];
    const maxSteps = lastTenActivities.length;

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    const isActivitiesPage = router.pathname === Rutas.ACTIVIDADES;

    const getTitleModified = (title) => {
        if (!title) return '';

        if (title === title.toUpperCase()) {
            return title
                .toLowerCase()
                .split('. ')
                .map((sentence) => sentence.charAt(0).toUpperCase() + sentence.slice(1))
                .join('. ');
        } else {
            return title;
        }
    };

    return (
        <Box>
            {maxSteps > 0 && (
                <>
                    <Paper
                        square
                        elevation={0}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            height: 20,
                            pl: 2,
                            bgcolor: 'background.default',
                        }}
                    ></Paper>
                    <AutoPlaySwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={activeStep}
                        onChangeIndex={handleStepChange}
                        enableMouseEvents
                        interval={100000}
                    >
                        {lastTenActivities.map((activity, index) => (
                            <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                                {Math.abs(activeStep - index) <= 2 ? (
                                    <CustomAlert
                                        textStyles={{
                                            whiteSpace: 'nowrap',
                                            overflowX: 'hidden',
                                            textOverflow: 'ellipsis',
                                        }}
                                        textStylesContainer={{
                                            maxWidth: 'calc(100% - 30px)',
                                        }}
                                        severity="info"
                                        text={getTitleModified(activity.title)}
                                        subText={isActivitiesPage ? '' : 'Ver todas las actividades'}
                                        icon={getIcon(activity)}
                                        onClick={() => router.push(Rutas.ACTIVIDADES)}
                                        styles={{ width: '100%' }}
                                    />
                                ) : null}
                            </div>
                        ))}
                    </AutoPlaySwipeableViews>
                </>
            )}
        </Box>
    );
};

export default ActividadBanner;
