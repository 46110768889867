import { useEffect, useState } from 'react';
import { Table, TableBody, TableContainer, TableHead, TableRow, Paper, Box, Typography } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { getCssClassForMarketValueDifference, getMarketValueDifference } from '@/helpers/players-helper';
import { getDate_dd_MM } from '@/helpers/datetime-helper';
import { toPriceInEuros } from '@/helpers/prices-helper';

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#fff',
        color: '#000',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const PlayerDataTable = ({ marketValues }) => {
    const [localMarketValues, setLocalMarketValue] = useState(
        marketValues.sort((a, b) => new Date(b.created) - new Date(a.created))
    );
    const getIcon = (value) => {
        switch (value) {
            case 'green-diff':
                return (
                    <ArrowUpwardIcon
                        style={{
                            color: '#4caf50',
                            fontSize: '13px',
                            position: 'relative',
                            bottom: '0px',
                            marginRight: '2px',
                        }}
                    />
                );
            case 'red-diff':
                return (
                    <ArrowDownwardIcon
                        style={{
                            color: '#f44336',
                            fontSize: '13px',
                            position: 'relative',
                            bottom: '0px',
                            marginRight: '2px',
                        }}
                    />
                );
            default:
                return '';
        }
    };

    const getSubidaBajadaIcon = (marketValue, nextMarketValue) => {
        const icon = getIcon(getCssClassForMarketValueDifference(marketValue, nextMarketValue));

        const cssClass = `${getCssClassForMarketValueDifference(marketValue, nextMarketValue)}`;

        const value = getMarketValueDifference(marketValue, nextMarketValue);

        if (marketValue - nextMarketValue === 0) {
            return <Typography>-</Typography>;
        }

        return (
            <Typography className={cssClass}>
                {icon}
                {value}
            </Typography>
        );
    };

    useEffect(() => {
        setLocalMarketValue(marketValues.sort((a, b) => new Date(b.created) - new Date(a.created)));
    }, [marketValues]);

    return (
        <Box>
            <Typography
                variant="h2"
                style={{ marginBottom: '5px', marginTop: '5px', fontWeight: 600, textAlign: 'center' }}
            >
                Últimos {localMarketValues?.length ?? 0} mercados
            </Typography>
            <TableContainer component={Paper}>
                <Table
                    className="player-market-values-table"
                    size="small"
                    aria-label="Market Values Table"
                    style={{ tableLayout: 'fixed' }}
                >
                    <TableHead>
                        <StyledTableRow>
                            <StyledTableCell colSpan={1}></StyledTableCell>
                            <StyledTableCell colSpan={3}>Fecha</StyledTableCell>
                            <StyledTableCell colSpan={4}>Subida/Bajada</StyledTableCell>
                            <StyledTableCell colSpan={4}>Valor</StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {localMarketValues?.map((item, index) => (
                            <StyledTableRow key={index}>
                                <StyledTableCell colSpan={1}>{index + 1}</StyledTableCell>
                                <StyledTableCell colSpan={3}>{getDate_dd_MM(item.created)}</StyledTableCell>
                                <StyledTableCell colSpan={4}>
                                    {getSubidaBajadaIcon(
                                        item?.marketValue,
                                        localMarketValues?.[index + 1]?.marketValue
                                    )}
                                </StyledTableCell>
                                <StyledTableCell colSpan={4}>
                                    {toPriceInEuros(item.marketValue)}
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default PlayerDataTable;
