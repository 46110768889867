import Image from 'next/image';

const CustomImageComponent = ({
    src,
    width,
    height,
    alt,
    className,
    style,
    onClick,
    unoptimized,
    fallbackSrc,
}) => {

    const customSrc = () => {
        if (!src) return `${process.env.NEXT_PUBLIC_IMAGES}/jugadores/0.png`;
        return src;
    };

    return (
        <Image
            src={customSrc()}
            width={width}
            height={height}
            alt={alt}
            className={className}
            style={style}
            onError={() => setImgSrc(fallbackSrc || `${process.env.NEXT_PUBLIC_IMAGES}/jugadores/0.png`)}
            onClick={onClick}
            unoptimized={unoptimized ?? true}
        />
    );
};

export default CustomImageComponent;
