const URL_FACTORY = {
    URL_Survey: `${process.env.NEXT_PUBLIC_URL_FUNCTION_BLOG}/survey`,
    URL_AnalisisPlantillaV2: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}players`,
    URL_ExecuteAnalysis: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}fantasy-analysis`,
    URL_GET_PUBLISHED_ARTICLES: `${process.env.NEXT_PUBLIC_URL_FUNCTION_BLOG}/publishedArticles`,
    URL_GET_RECENT_ARTICLES: `${process.env.NEXT_PUBLIC_URL_FUNCTION_BLOG}/publishedArticles`,
    GET_URL_EstadisticasGlobales: (temporada, jornadaActiva) => {
        const url = `${
            process.env.NEXT_PUBLIC_URL_FUNCTION
        }lfmPlayers/playersStats?temporada=${temporada}&jornadaActiva=${jornadaActiva ?? -1}`;
        return url;
    },
    GetTeamImage: (teamSlug) => {
        return GetTeamImageLogo(teamSlug);
    },
    GetTeamImageLogo: (teamSlug) => {
        const width = 51;
        if (teamSlug) {
            return `${process.env.NEXT_PUBLIC_IMAGES}/teams/${teamSlug}.png${width ? `?width=${width}` : ''}`;
        }

        return `${process.env.NEXT_PUBLIC_IMAGES}/teams/unknown-teams.png${width ? `?width=${width}` : ''}`;
    },
    ObtenerCalendarioFantasy: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}calendario-fantasy`,
    GetFantasyAdminCalendar: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}calendario-admin`,
    SaveAdminCalendar: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}save-calendario-admin`,
    PUT_URL_UpdatePlayerSquad: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}squad`,
    GET_URL_GetRanking: (league, season) => {
        const url = `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}ranking?league=${league}&season=${season}`;
        return url;
    },
    GET_URL_GetSquad: (league, season, round) => {
        const url = `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}squad?league=${league}&season=${season}&round=${round}`;
        return url;
    },
    GET_URL_GetFixtureRounds: (league, season) => {
        const url = `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}fixture-rounds?league=${league}&season=${season}`;
        return url;
    },
    GET_URL_AlineacionesProbables_Initial: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}lineups/initial`,
    GET_URL_Predicting_Manager_Ranking: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}predicting-scores/ranking`,
    GetPlayersForImage: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}players-v2`,
    GET_URL_ChangePlayerStatus: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}player/update`,
    GET_URL_UpdateLineup: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}lineups/update`,
    GET_URL_TeamLineups: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}teams-lineups`,
    GET_URL_ManagerLineups: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}manager-lineups`,
    GET_URL_FixtureLineups: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}fixture-lineups`,
    GET_URL_GetUsers: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}users`,
    GET_URL_GetTeamPlayersLineups: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}teams-player-lineups`,
    GET_URL_GetFantasyPlayers: (season) =>
        `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}fantasy-players/${season}`,

    GET_URL_GetFantasyPlayersStats: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}fantasy-stats`,
    GET_URL_FantasyPlayers: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}fantasy-players`,
    UpsertFantasySquad: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}fantasy-squad/upsert`,
    GetFantasySquads: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}fantasy-squads`,
    ShareFantasySquads: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}fantasy-squad/share`,
    GetSharedFantasySquad: (code) =>
        `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}fantasy-squad/shared/${code}`,
    DeleteFantasySquad: (id) => `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}fantasy-squad/${id}`,
    GET_URL_FANTASY_SQUAD: (id) => `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}fantasy-squad/${id}`,
    GET_URL_FANTASY_MARKET_VALUES: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}fantasy-players/market-values`,
    GET_URL_FANTASY_LAST_MARKET_VALUES: (id) =>
        `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}fantasy-players/market-values/${id}`,
    GetUserProfile: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}user-profile`,
    GET_URL_RecentFixtures: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}recent-fixtures-v2`,
    POST_URL_GetFixtures: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}fixtures`,
    GetFixturesAlineacionesProbables: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}next-admin-lineups`,
    POST_URL_TeamCalendar: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}fixtures/team`,
    UpdateUserPassword: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}user-password`,
    GetFixtureBox: (fixtureId) => `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}fixture-box/${fixtureId}`,
    GetFixturePrediction: (fixtureId) =>
        `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}fixture-predictions/${fixtureId}`,
    GetLiveMatchesStats: (fixtureId) =>
        `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}fixture-fantasy-stats/${fixtureId}`,
    GetFantasyAnalysis: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}fantasy-analysis`,
    GetFantasyPlayers: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}fantasy-players`,
    GetFixtureFantasyStats: (fixtureId) =>
        `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}fixture-fantasy-stats/${fixtureId}`,
    GetBiwengerPlayers: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}biwenger-players`,
    UploadImage: (playerId) => `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}upload-image/${playerId}`,
    UpdateParteMedico: (playerId) =>
        `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}update-parte-medico/${playerId}`,
    PlayerAnalysis: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}fantasy-analysis`,
    GetFantasyPlayer: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}fantasy-player`,
    GetAllPlayers: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}all-players`,
    GetQuinielaConfiguration: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}quiniela-configuration`,
    GetActiveQuiniela: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}quiniela`,
    SaveActiveQuiniela: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}quiniela`,
    GetPlayerTemplateImage: () => `${process.env.NEXT_PUBLIC_IMAGES}/jugadores/0.png`,
    GetCountryImage: (countryCode, width) =>
        `${process.env.NEXT_PUBLIC_IMAGES}/countries/${countryCode}.png?width=${width ?? 15}`,
    GetCompetitionImage: (competitionCode, width) =>
        `${process.env.NEXT_PUBLIC_IMAGES}/competitions/${competitionCode}.png?width=${width ?? 20}`,
    GetUniquePlayerImage: (image, width, playerId) => {
        if (!image && playerId) {
            return `${process.env.NEXT_PUBLIC_IMAGES}/jugadores/${playerId}.png`;
        }
        if (image.includes('-')) {
            return image;
        }

        const url = `${image}${width ? `?width=${width}` : ''}`;
        return url;
    },
    MejoresRachas: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}mejores-rachas`,
    PujaFantasy: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}puja-fantasy`,
    ChollosFantasy: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}chollos-fantasy`,
    SaveQuinielaConfiguration: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}quiniela-configuration`,
    GetPlayersByStatus: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}players-status`,
    GetNoticiasFlash: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}noticias-flash`,
    GetNoticiasFlashAdmin: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}noticias-flash-admin`,
    DeleteNoticiasFlashAdmin: (id) => `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}noticia-flash/${id}`,
    EditNoticiasFlashAdmin: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}noticia-flash/update`,
    GetFixtures: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}fixtures`,
    GetConfiguration: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}configuration`,
    SaveConfiguration: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}configuration/update`,
    GetAdminLineups: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}lineups/admin`,
    SaveAdminLineups: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}lineups/admin/update`,
    GetFullLineups: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}lineups`,
    GetColaboradores: `${process.env.NEXT_PUBLIC_URL_FUNCTION_BLOG}/colaboradores`,
    GetPlayerProfile: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}player-profile`,
    GetHistoricFantasy: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}historic`,
    GetPlayersProfile: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}players-profile`,
    GetConvocatorias: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}convocatoria`,
    UpdateAlineacionesConfirmadas: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}alineaciones-confirmadas/update`,
    UpdateConvocatorias: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}convocatoria/update`,
    PublishConvocatorias: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}convocatoria/publish`,
    GetPublishedConvocatoria: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}convocatoria/published`,
    GetVirusFifa: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}convocatoria-seleccion`,
    UpdateVirusFifa: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}convocatoria-seleccion/update`,
    GetStanding: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}standing`,
    RegisterUserUrl: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}register`,
    LoginUserUrl: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}login`,
    DeleteAccount: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}delete-account`,
    ForgotPassword: (username) => `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}forgot-password/${username}`,
    UpdatePassword: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}update-user-password`,
    ConfirmEmail: (token) => `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}confirm-email/${token}`,
    ResetPassword: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}reset-password`,
    ResendEmailConfirmation: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}verify-email`,
    SyncPlayerImages: (id) => `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}sync-images/${id}`,
    SearchPlayer: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}search-player`,
    GetBiwengerEstadisticas: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}biwenger-estadisticas`,
    GetAccounts: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}accounts`,
    UpsertAccount: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}upsert-account`,

    GetVideo: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}get-video`,
    GetVideos: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}get-videos`,
    UpsertVideo: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}upsert-video`,
    GetEstadisticasFantasy: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}get-fantasy-estadisticas`,
    GetSeoFixture: (fixtureId) => `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}seo-fixture/${fixtureId}`,
    GetParticiparSorteo: (id) => `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}sorteos/${id}`,
    GetSorteo: (id) => `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}sorteo/${id}`,
    ParticiparSorteo: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}sorteos`,
    GetActivities: `${process.env.NEXT_PUBLIC_URL_FUNCTION_BLOG}/activities`,
    GetVotes: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}votes`,
    AddVote: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}add-vote`,
    GetOnceTipo: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}once-tipo-admin`,
    SaveOnceTipo: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}upsert-once-tipo-admin`,
    GetOnceTipoUser: (teamId) => `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}get-once-tipo/${teamId}`,
    GetLanzadoresDePenaltis: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}lanzadores-penaltis`,
    ObtenerTitularidades: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}titularidades`,

    PublicTierlists: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}public-tierlists`,
    ListTierlists: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}tierlist`,
    ViewTierlist: (slug) => `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}ver-tierlist/${slug}`,
    GetTierlist: (slug) => `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}tierlist/${slug}`,
    CreateTierlist: `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}tierlist`,
    DeleteTierlist: (id) => `${process.env.NEXT_PUBLIC_URL_FANTASY_MANAGER}tierlist/${id}`,
};

export default URL_FACTORY;
