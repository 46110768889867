import { LEAGUES } from '@/constants/leagues';
import { replaceDiacritis } from '@/helpers/players-helper';

const equipos = [
    { fid: 21, id: 542, short: 'Alavés', name: 'Alavés', leagues: [LEAGUES.LA_LIGA], slug: 'd-alaves' },
    {
        fid: 3,
        id: 531,
        short: 'Athletic',
        name: 'Athletic Club',
        leagues: [LEAGUES.LA_LIGA],
        slug: 'athletic-club',
    },
    {
        fid: 2,
        id: 530,
        short: 'Atleti',
        name: 'Atlético de Madrid',
        leagues: [LEAGUES.LA_LIGA, LEAGUES.CHAMPIONS_LEAGUE],
        slug: 'atletico-de-madrid',
    },
    {
        fid: 4,
        id: 529,
        short: 'Barça',
        name: 'Barcelona',
        leagues: [LEAGUES.LA_LIGA, LEAGUES.CHAMPIONS_LEAGUE],
        slug: 'fc-barcelona',
    },
    { fid: 6, id: 538, short: 'Celta', name: 'Celta de Vigo', leagues: [LEAGUES.LA_LIGA] },
    { fid: 8, id: 540, short: 'Espanyol', name: 'Espanyol', leagues: [LEAGUES.LA_LIGA] },
    { fid: 28, id: 547, short: 'Girona', name: 'Girona', leagues: [LEAGUES.LA_LIGA], slug: 'girona-fc' },
    { fid: 9, id: 546, short: 'Getafe', name: 'Getafe', leagues: [LEAGUES.LA_LIGA], slug: 'getafe-cf' },
    {
        fid: 31,
        id: 534,
        short: 'Las Palmas',
        name: 'Las Palmas',
        leagues: [LEAGUES.LA_LIGA],
        slug: 'ud-las-palmas',
    },
    { fid: 54, id: 537, short: 'Leganés', name: 'Leganés', leagues: [LEAGUES.LA_LIGA], slug: 'cd-leganes' },
    { fid: 33, id: 798, short: 'Mallorca', name: 'Mallorca', leagues: [LEAGUES.LA_LIGA] },
    { fid: 13, id: 727, short: 'Osasuna', name: 'Osasuna', leagues: [LEAGUES.LA_LIGA] },
    {
        fid: 14,
        id: 728,
        short: 'Rayo',
        name: 'Rayo Vallecano',
        leagues: [LEAGUES.LA_LIGA],
        slug: 'rayo-vallecano',
    },
    {
        fid: 16,
        id: 548,
        short: 'R.Sociedad',
        name: 'Real Sociedad',
        leagues: [LEAGUES.LA_LIGA, LEAGUES.CHAMPIONS_LEAGUE],
        slug: 'real-sociedad',
    },
    {
        fid: 15,
        id: 541,
        short: 'R.Madrid',
        name: 'Real Madrid',
        leagues: [LEAGUES.LA_LIGA, LEAGUES.CHAMPIONS_LEAGUE],
    },
    { fid: 5, id: 543, short: 'Betis', name: 'Real Betis', leagues: [LEAGUES.LA_LIGA] },
    { fid: 17, id: 536, short: 'Sevilla', name: 'Sevilla', leagues: [LEAGUES.LA_LIGA], slug: 'sevilla-fc' },
    { fid: 18, id: 532, short: 'Valencia', name: 'Valencia', leagues: [LEAGUES.LA_LIGA] },
    { fid: 19, id: 720, short: 'Valladolid', name: 'Valladolid', leagues: [LEAGUES.LA_LIGA] },
    {
        fid: 20,
        id: 533,
        short: 'Villarreal',
        name: 'Villarreal',
        leagues: [LEAGUES.LA_LIGA],
        slug: 'villarreal-cf',
    },
];

export const obtenerEquipoPorFantasyId = (fantasyId) => {
    return equipos.find((x) => x.fid == fantasyId);
};

export const obtenerLigas = () => {
    return [
        { id: LEAGUES.LA_LIGA, name: 'La Liga' },
        { id: LEAGUES.EUROCOPA, name: 'Eurocopa' },
    ];
};

export const obtenerEquiposPorCategoria = (isCountry) => {
    if (isCountry === true) {
        return equipos.filter((x) => x.isCountry);
    } else if (isCountry === false) {
        return equipos.filter((x) => !x.isCountry);
    }

    return equipos;
};

export const obtenerEquipos = (leagueId, addAll) => {
    let equiposToReturn = [...equipos];
    if (leagueId > 0) {
        equiposToReturn = equipos.filter((x) => x.leagues.includes(leagueId));
    } else {
        equiposToReturn = equipos;
    }

    if (addAll) {
        equiposToReturn.unshift({ id: 'all', name: 'Todos' });
    }

    return equiposToReturn;
};

export const obtenerEquipo = (teamId) => {
    if (teamId > 0) {
        return equipos.find((x) => x?.id == teamId);
    }

    return teamId;
};

export const obtenerEquipoPorIdOrSlug = (teamId, slug) => {
    if (teamId > 0) {
        return equipos.find((x) => x?.id == teamId);
    } else {
        return equipos.find((x) => x?.slug == slug);
    }
};

export const toTeamSlug = (name) => {
    if (name) {
        const nameWithoutSpaces = name.replace(/ /g, '-').toLowerCase();
        return `-${replaceDiacritis(nameWithoutSpaces)}`;
    }

    return '';
};

export const isCountryTeamLeague = (leagueId) => {
    switch (leagueId) {
        case LEAGUES.EUROCOPA:
        case LEAGUES.COPA_AMERICA:
        case LEAGUES.FIFA_WORLD_CLUB:
        case LEAGUES.NATIONS_LEAGUE:
        case LEAGUES.FIFA_FRIENDLIES:
        case LEAGUES.EURO_CLASIFICACION:
            return true;
        default:
            return false;
    }
};

export const getTranslatedTeamName = (teamId, teamName) => {
    const team = obtenerEquipo(teamId);

    if (team) {
        return team.name;
    }

    return teamName;
};
