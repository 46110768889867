import { Typography, Checkbox, FormControlLabel } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import CustomTabs from './custom-tabs';
import CustomImageComponent from '@/components/shared/custom/custom-image';
import { Context } from '@/context';
import { FANTASY } from '@/helpers/biwenger-helper';

const estadisticas = [
    { title: 'Minutos disputados', key: 'mins_played' },
    { title: 'Goles', key: 'goals' },
    { title: 'Asistencias de gol', key: 'goal_assist' },
    { title: 'Asistencias sin gol', key: 'offtarget_att_assist' },
    { title: 'Balones al área', key: 'pen_area_entries' },
    { title: 'Penaltis provocados', key: 'penalty_won' },
    { title: 'Penaltis parados', key: 'penalty_save' },
    { title: 'Paradas', key: 'saves' },
    { title: 'Despejes', key: 'effective_clearance' },
    { title: 'Penaltis fallados', key: 'penalty_failed' },
    { title: 'Goles en propia puerta', key: 'own_goals' },
    { title: 'Goles en contra', key: 'goals_conceded' },
    { title: 'Tarjetas amarillas', key: 'yellow_card' },
    { title: 'Segundas amarillas', key: 'second_yellow_card' },
    { title: 'Tarjetas rojas', key: 'red_card' },
    { title: 'Tiros a puerta', key: 'total_scoring_att' },
    { title: 'Regates', key: 'won_contest' },
    { title: 'Balones recuperados', key: 'ball_recovery' },
    { title: 'Posesiones perdidas', key: 'poss_lost_all' },
    { title: 'Penaltis cometidos', key: 'penalty_conceded' },
];

const biwengerEstadisticas = [
    { title: 'Entró', key: 'in' },
    { title: 'Salió', key: 'out' },
    { title: 'Goles', key: 'goals' },
    { title: 'Asistencias', key: 'assistances' },
    { title: 'Tarjetas amarilla', key: 'yellowCard' },
    { title: 'Segundas amarillas', key: 'secondYellowCard' },
    { title: 'Tarjetas Roja', key: 'redCard' },
];

const estadisticaRelevo = {
    title: 'Puntos relevo',
    key: 'marca_points',
};

const PuntuacionTabComponent = ({ player }) => {
    const [value, setValue] = useState(player?.weeks?.length > 0 ? Math.max(...player.weeks) : 0);
    const [stats, setStats] = useState([]);
    const [showOnlyWithValues, setShowOnlyWithValues] = useState(true); // Estado para el checkbox
    const { state } = useContext(Context);
    const { preferences } = state;

    const getColor = (puntos) => {
        if (puntos > 0) {
            return '#00b300';
        } else if (puntos < 0) {
            return '#ff0000';
        } else {
            return '#000000';
        }
    };

    const getCantidad = (estadistica, position) => {
        if (preferences.fantasy === FANTASY.BIWENGER) {
            const weekStats = player?.biwengerStats?.find((x) => x.week == value);
            const cantidad = weekStats?.partido?.stats?.[estadistica.key];

            switch (estadistica.key) {
                case 'in':
                case 'out':
                case 'goals':
                case 'assistances':
                    return position === 1 ? '-' : cantidad;
                case 'yellowCard':
                case 'secondYellowCard':
                case 'redCard':
                    if (cantidad) {
                        return position === 0 ? 1 : cantidad;
                    }
                    return position === 0 ? 0 : '-';
                default:
                    return cantidad ?? 0;
            }
        } else {
            const weekStats = player?.playerStats?.find((x) => x.weekNumber === value);
            const cantidad = weekStats?.stats?.[estadistica.key]?.[position];

            return cantidad ?? '-';
        }
    };

    const getTotalPoints = () => {
        const weekStats = player?.playerStats?.find((x) => x.weekNumber === value);

        return weekStats?.totalPoints ?? '-';
    };

    const handleClick = (week) => {
        setValue(week);
    };

    useEffect(() => {
        if (preferences.fantasy === FANTASY.BIWENGER) {
            setStats(biwengerEstadisticas);
        } else {
            setStats(estadisticas);
        }
    }, [preferences.fantasy]);

    // Función para verificar si la estadística tiene valores distintos de 0
    const hasValues = (estadistica) => {
        return getCantidad(estadistica, 0) !== 0 || getCantidad(estadistica, 1) !== 0;
    };

    return (
        <div className="journeys-container">
            <CustomTabs player={player} handleClick={handleClick} selected={value} />

            <div className="estadisticas-jornada">
                <div style={{ paddingLeft: '8px', display: 'flex', alignItems: 'center' }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={showOnlyWithValues}
                                onChange={() => setShowOnlyWithValues(!showOnlyWithValues)}
                            />
                        }
                        label="Mostrar solo estadísticas con valores"
                    />
                </div>
                <div className="estadisticas-jornada__title">
                    <Typography style={{ fontWeight: 600 }}>
                        {value > 0 ? `Jornada ${value}` : '-'}
                    </Typography>
                </div>

                <div className="estadisticas-jornada__header">
                    <div className="estadisticas-jornada__column-left">
                        <Typography variant="h6">Cantidad</Typography>
                    </div>
                    <div className="estadisticas-jornada__column-center">
                        <Typography variant="h6">Estadísticas</Typography>
                    </div>
                    <div className="estadisticas-jornada__column-right">
                        <Typography variant="h6">Puntos</Typography>
                    </div>
                </div>

                {stats
                    ?.filter((estadistica) => (showOnlyWithValues ? hasValues(estadistica) : true)) // Filtra si está marcado
                    .map((estadistica, index) => (
                        <div
                            key={index}
                            className="estadisticas-jornada__line"
                            style={{
                                backgroundColor:
                                    getCantidad(estadistica, 0) > 0 || getCantidad(estadistica, 1) > 0
                                        ? '#f0f0f0'
                                        : '#fff',
                            }}
                        >
                            <div className="estadisticas-jornada__column-left">
                                <Typography variant="h6">{getCantidad(estadistica, 0) ?? '-'}</Typography>
                            </div>
                            <div className="estadisticas-jornada__column-center">
                                <Typography variant="h6" style={{ fontWeight: 400 }}>
                                    {estadistica.title}
                                </Typography>
                            </div>
                            <div className="estadisticas-jornada__column-right">
                                <Typography
                                    variant="h6"
                                    style={{ color: getColor(getCantidad(estadistica, 1)), fontWeight: 400 }}
                                >
                                    {getCantidad(estadistica, 1) ?? '-'}
                                </Typography>
                            </div>
                        </div>
                    ))}

                {preferences.fantasy !== FANTASY.BIWENGER && (
                    <>
                        <div
                            className="estadisticas-jornada__line"
                            style={{
                                backgroundColor: getCantidad(estadisticaRelevo, 1) > 0 ? '#f0f0f0' : '#fff',
                            }}
                        >
                            <div className="estadisticas-jornada__column-left">
                                <Typography variant="h6">
                                    <CustomImageComponent
                                        src={`${process.env.NEXT_PUBLIC_IMAGES}/competitions/relevo.png?width=100&height=40`}
                                        alt="relevo"
                                        width={100}
                                        height={40}
                                    />
                                </Typography>
                            </div>
                            <div className="estadisticas-jornada__column-center">
                                <Typography variant="h6">Puntos relevo</Typography>
                            </div>
                            <div className="estadisticas-jornada__column-right">
                                <Typography
                                    variant="h6"
                                    style={{ color: getColor(getCantidad(estadisticaRelevo, 1)) }}
                                >
                                    {getCantidad(estadisticaRelevo, 1)}
                                </Typography>
                            </div>
                        </div>

                        <div
                            className="estadisticas-jornada__header"
                            style={{
                                backgroundColor: '#22272f',
                                color: '#fff',
                            }}
                        >
                            <div className="estadisticas-jornada__column-left">
                                <Typography variant="h6">
                                    <CustomImageComponent
                                        src={`${process.env.NEXT_PUBLIC_IMAGES}/competitions/la-liga.png?width=20&height=20`}
                                        alt="la-liga"
                                        width={20}
                                        height={20}
                                    />
                                </Typography>
                            </div>
                            <div className="estadisticas-jornada__column-center">
                                <Typography variant="h6">Total</Typography>
                            </div>
                            <div className="estadisticas-jornada__column-right">
                                <Typography variant="h6">{getTotalPoints()}</Typography>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default PuntuacionTabComponent;
