import { REDUCERS } from "@/constants/reducers";

export function fixtures(state, action) {
  switch (action.type) {
    case REDUCERS.UPDATE_FIXTURES_ROUND:
      let fixtures = { ...state.fixtures, ...action.payload };
      return { ...state, fixtures };
    default:
      return state;
  }
}
