import { Alert, Typography } from '@mui/material';
import TipsIcon from './icons/tips-icon';
import { getStatusInfo, getPlayersStatus, getPlayerIcon } from '@/helpers/players-helper';
import { PLAYER_STATUS } from '@/constants/player-constants';

const PlayerInfoCardStatus = ({ player }) => {
    const getSeverity = () => {
        switch (player?.generalStatus?.status) {
            case PLAYER_STATUS.SANCTIONED:
            case PLAYER_STATUS.INJURED:
                return 'error';
            case PLAYER_STATUS.DOUBTFUL:
                return 'warning';
            case PLAYER_STATUS.OK:
            default:
                return 'success';
        }
    };

    const getReport = (p) => {
        if (p?.generalStatus?.status === PLAYER_STATUS.OK) {
            return null;
        }

        return (
            <div className="player-information-container__item-info-status">
                <div style={{ width: '15px' }}>
                    <TipsIcon style={{ fontSize: '16px', color: '#01aa4d' }} />
                </div>
                <div>{p?.generalStatus?.report}</div>
            </div>
        );
    };

    return (
        <div className="player-infocard-status">
            <Alert severity={getSeverity()} icon={false}>
                <Typography className="player-infocard-status__status">
                    {getPlayerIcon(player?.generalStatus?.status, player?.generalStatus?.statusLevel)}
                    {getPlayersStatus(player?.generalStatus?.status, player?.generalStatus?.statusLevel)}
                </Typography>

                {player?.generalStatus?.statusInfo && (
                    <Typography className="player-infocard-status__status">
                        {getStatusInfo(player)}
                    </Typography>
                )}

                {player?.generalStatus?.report && (
                    <Typography className="player-infocard-status__status">{getReport(player)}</Typography>
                )}
            </Alert>
        </div>
    );
};

export default PlayerInfoCardStatus;
