import { Box, Typography } from '@mui/material';
import Select from 'react-select';

const ReactSelect = (props) => {
    const {
        isMulti,
        placeholder,
        defaultValue,
        value,
        options,
        id,
        text,
        width,
        isDisabled,
        isLoading,
        onChange,
        icon,
        isVisible,
        onClose,
    } = props;

    const customStyles = {
        control: (provided) => ({
            ...provided,
            width: width ?? '100%',
        }),
        menu: (provided) => ({
            ...provided,
            width: width ?? '100%',
        }),
    };

    const onChangeEvent = (e) => {
        onChange(e);
    };

    return (
        <Box className="football-stats-selector">
            <Typography className="football-stats-selector__header">
                {text}{' '}
                {icon && (
                    <Typography className="header-icon" onClick={onClose}>
                        {icon}
                    </Typography>
                )}
            </Typography>
            <Select
                instanceId={id}
                styles={customStyles}
                options={options}
                onChange={onChangeEvent}
                value={value}
                defaultValue={defaultValue}
                placeholder={placeholder}
                isMulti={isMulti}
                isDisabled={isDisabled}
                isLoading={isLoading}
                icon={icon}
                isVisible={isVisible}
            />
        </Box>
    );
};

export default ReactSelect;
