import { useEffect, useRef, useState } from 'react';

const AdsenseFooterHorizontal1 = () => {
    const [isVisible, setIsVisible] = useState(false);
    const adRef = useRef(null); // Referencia para el div que contiene el anuncio

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsVisible(true); // Cuando el componente entra en el viewport, se hace visible
                        observer.disconnect(); // Deja de observar una vez que el componente es visible
                    }
                });
            },
            { threshold: 0.1 } // El anuncio debe estar al menos un 10% visible para activarse
        );

        if (adRef.current) {
            observer.observe(adRef.current); // Empieza a observar el contenedor del anuncio
        }

        return () => {
            if (observer && adRef.current) {
                observer.unobserve(adRef.current); // Limpieza del observer al desmontar
            }
        };
    }, []);

    useEffect(() => {
        if (isVisible && window.adsbygoogle) {
            try {
                (window.adsbygoogle = window.adsbygoogle || []).push({});
            } catch (e) {
                console.error('Adsense error:', e);
            }
        }
    }, [isVisible]);

    return (
        <div
            id="adsense-footer"
            ref={adRef}
            style={{
                width: '100%',
                minHeight: '250px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            {isVisible && (
                <ins
                    className="adsbygoogle"
                    style={{ display: 'block', width: '100%', height: '250px' }}
                    data-ad-client="ca-pub-1884623822045383"
                    data-ad-slot={1025082374}
                    data-ad-format="auto"
                    data-full-width-responsive="true"
                ></ins>
            )}
        </div>
    );
};

export default AdsenseFooterHorizontal1;
