import RectangleIcon from '@mui/icons-material/Rectangle';
import DangerousIcon from '@mui/icons-material/Dangerous';
import ArieteIcon from '@/components/shared/icons/recommendation-icons/ariete-icon';

export const helperEvents = {
    getEventIcon: (type, detail, isRemoved) => {
        switch (type.toLowerCase()) {
            case 'goal':
                if (detail === 'Own Goal') {
                    return (
                        <svg xmlns="http://www.w3.org/2000/svg" width={17} height={17} viewBox="0 0 36 36">
                            <circle cx="18" cy="18" r="18" fill="#F5F8FA" />
                            <path
                                fill="#CCD6DD"
                                d="M18 11a1 1 0 0 1-1-1V3a1 1 0 0 1 2 0v7a1 1 0 0 1-1 1zm-6.583 4.5a.99.99 0 0 1-.302-.047l-8.041-2.542a1 1 0 1 1 .603-1.907l8.042 2.542a1 1 0 0 1-.302 1.954zm13.625-.291a1.001 1.001 0 0 1-.278-1.961l6.75-1.958a1 1 0 0 1 .556 1.921l-6.75 1.958a.989.989 0 0 1-.278.04zm2.001 14.958a1 1 0 0 1-.803-.403l-5.459-7.333a1 1 0 1 1 1.604-1.194l5.459 7.333a1 1 0 0 1-.801 1.597zm-18.294-.083a1 1 0 0 1-.82-1.571l5.125-7.375a1 1 0 1 1 1.642 1.141l-5.125 7.375a.996.996 0 0 1-.822.43zM3.5 27.062c-.44 0-.844-.293-.965-.738L.347 18.262a1 1 0 1 1 1.931-.523l2.188 8.062a.998.998 0 0 1-.966 1.261zM22 34h-9a1 1 0 1 1 0-2h9a1 1 0 1 1 0 2zm10.126-6.875a.999.999 0 0 1-.971-1.239l2.125-8.625a1 1 0 1 1 1.941.479l-2.125 8.625c-.113.455-.521.76-.97.76zM30.312 7.688a.998.998 0 0 1-.5-.134L22.25 3.179a1 1 0 0 1-.364-1.367a.998.998 0 0 1 1.366-.365l7.562 4.375a1 1 0 0 1-.502 1.866zm-24.811 0a1 1 0 0 1-.584-1.813l6.188-4.438a1.001 1.001 0 0 1 1.167 1.625L6.083 7.5a1 1 0 0 1-.582.188z"
                            />
                            <path
                                fill="red"
                                d="m25.493 13.516l-7.208-5.083a1.002 1.002 0 0 0-1.161.006l-7.167 5.167a1 1 0 0 0-.375 1.091l2.5 8.583a1 1 0 0 0 .96.72H22a1 1 0 0 0 .948-.681l2.917-8.667a1 1 0 0 0-.372-1.136zM1.292 19.542a1 1 0 0 0 .872-.51l3.375-6a1 1 0 0 0 .108-.291L6.98 6.2A.999.999 0 0 0 6 5h-.428C2.145 8.277 0 12.884 0 18c0 .266.028.525.04.788l.602.514c.182.156.413.24.65.24zm9.325-16.547a.748.748 0 0 0 .553.412l6.375 1.042a.823.823 0 0 0 .243 0l6.084-1a.748.748 0 0 0 .613-.889l-.292-1.443A17.912 17.912 0 0 0 18 0c-2.425 0-4.734.486-6.845 1.356l-.521.95a.752.752 0 0 0-.017.689zm20.517 2.724l-1.504-.095a.758.758 0 0 0-.609.249a.741.741 0 0 0-.175.63l1.167 6.198a.666.666 0 0 0 .093.224c1.492 2.504 3.152 5.301 3.381 5.782c.024.084.062.079.114.151c.14.195.372.142.612.142h.007c.198 0 .323.094 1.768-.753c.001-.083.012-.164.012-.247c0-4.753-1.856-9.064-4.866-12.281zM14.541 33.376a.755.755 0 0 0-.191-.544l-4.5-5a.746.746 0 0 0-.211-.163c-5.885-3.069-5.994-3.105-6.066-3.13a.8.8 0 0 0-.242-.039c-.537 0-.695.065-1.185 2.024a18.044 18.044 0 0 0 10.644 8.703l1.5-1.333a.753.753 0 0 0 .251-.518zm17.833-8.567a.747.747 0 0 0-.592.005l-6.083 2.667a.738.738 0 0 0-.274.205l-4.25 5.083a.752.752 0 0 0 .1 1.062c.683.559 1.261 1.03 1.767 1.44a18.017 18.017 0 0 0 10.454-8.146l-.712-1.889a.749.749 0 0 0-.41-.427z"
                            />
                        </svg>
                    );
                } else if (detail === 'Missed Penalty') {
                    return <DangerousIcon className="custom-own-goal-icon custom-event-icon"></DangerousIcon>;
                } else {
                    return <ArieteIcon style={{ marginLeft: '4px', marginRight: '2px' }}></ArieteIcon>;
                }
            case 'card':
                if (detail === 'Red Card') {
                    return <RectangleIcon className="custom-red-card-icon custom-event-icon"></RectangleIcon>;
                } else {
                    if (isRemoved) {
                        return <div className="removed-yellow-card">X</div>;
                    }
                    return (
                        <RectangleIcon className="custom-yellow-card-icon custom-event-icon"></RectangleIcon>
                    );
                }
            case 'subst':
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                        <path fill="#059669" d="M15 20H9v-8H4.16L12 4.16L19.84 12H15v8Z" />
                    </svg>
                );
            case 'var':
                return (
                    <div
                        style={{
                            fontSize: '8px',
                            borderRadius: '4px',
                            width: '20px',
                            height: '17px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: '#fff',
                            backgroundColor: '#e11d48',
                            marginRight: '4px',
                        }}
                    >
                        VAR
                    </div>
                );
            default:
                break;
        }
    },
    getSecondEventIcon: (type, detail) => {
        switch (type.toLowerCase()) {
            case 'goal':
                if (detail !== 'Missed Penalty') {
                    return (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17"
                            height="17"
                            viewBox="0 0 36 36"
                            style={{ marginLeft: '4px' }}
                        >
                            <path
                                fill="#8899A6"
                                d="m24.161 10.166l-.676-2.034a.564.564 0 0 0-.781-.275c-.918.487-2.944 1.318-3.684 1.575c1.419.505 3.499 1.503 4.511 1.396c.384-.04.787-.308.63-.662zm10.21 24.574c-.399.225-6.509 1.692-18.621-8.906C12.083 22.625 1.2 12.879 1.341 12.547c0 0-.329.36-.602.736c-.197.271-.319.559-.274.848c.31 1.967 3.112 3.819 6.962 6.781l.549.422l.363.279c.588.452 2.316 1.815 4.329 3.403c2.753 2.171 8.284 6.49 8.445 6.61c2.12 1.574 4.635 2.817 7.667 3.672c3.838 1.081 5.591-.558 5.591-.558z"
                            />
                            <path
                                fill="#BE1931"
                                d="M34.371 34.74s.477-.219.97-.918c.493-.699.79-1.924.045-3.248c-.745-1.323-3.456-5.121-4.345-6.671c-.433-.755-.297-1.447-1.125-2.835c0 0-3.164-5.523-3.864-6.723c-1.497-2.567-.84-3.902-1.837-4.668c-.665-.511-7.306.166-9.327-1.389c-1.397-1.074-1.418-4.428-2.003-5.526c-.466-.875-2.02-.475-3.087.11S5.825 6.661 5.123 7.574s-1.601 1.909-1.679 2.928l-.079 1.018S19.226 26.67 23.74 29.726c7.697 5.211 10.631 5.014 10.631 5.014z"
                            />
                            <path
                                fill="#A0041E"
                                d="M24.74 10.464c-.103-.314-.249-.575-.525-.788c-.665-.511-7.306.167-9.327-1.389c-1.397-1.074-1.418-4.429-2.003-5.526c-.182-.341-.532-.485-.952-.502c.69 1.4.621 5.574 2.312 6.874c2.038 1.569 8.016 1.133 10.495 1.331z"
                            />
                            <path
                                fill="#DD2E44"
                                d="M13.265 17.873c.505-1.472 1.044-4.915.074-6.962c-.909-1.917-4.441-5.955-5.112-6.72C6.987 5.296 5.33 7.229 4.83 7.879c-.66.858-1.455 1.822-1.494 2.801c-.014.352.087.51.087.51s9.822 6.741 9.842 6.683z"
                            />
                            <path
                                fill="#CCD6DD"
                                d="M23.107 14.256a.838.838 0 0 1-.306-1.62l1.823-.715a.84.84 0 0 1 .613 1.562l-1.823.715a.848.848 0 0 1-.307.058zm1.347 3.063a.838.838 0 0 1-.419-1.566l1.793-1.031a.839.839 0 0 1 .836 1.454l-1.793 1.031a.832.832 0 0 1-.417.112zm1.821 2.752a.838.838 0 0 1-.502-1.511l1.514-1.129a.84.84 0 0 1 1.002 1.345l-1.514 1.128a.828.828 0 0 1-.5.167zm1.945 2.571a.84.84 0 0 1-.532-1.488l1.155-.948a.84.84 0 0 1 1.065 1.297l-1.155.948a.842.842 0 0 1-.533.191zM3.2 10.76s.675.612 1.425.726c.75.114 2.079.95 2.993 1.653c.914.703 4.399 3.292 6.534 5.444s5.495 6.58 6.801 7.863c1.306 1.282 3.413 3.193 5.214 4.347s3.455 2.131 4.516 2.686c1.368.716 2.632 1.144 3.688 1.261c-.368.216-2.313.946-5.69-.205c-3.228-1.101-5.332-2.294-7.071-3.586c-1.739-1.292-11.208-8.808-12.759-10.001c-1.551-1.193-6.188-4.728-6.92-5.787c-1.04-1.504-.99-2.162-.788-2.424c.492-.641 1.531-2.382 2.057-1.977z"
                            />
                            <path
                                fill="#DD2E44"
                                d="M35.386 30.574c-.745-1.323-3.456-5.121-4.345-6.671c-.174-.304-.257-.599-.347-.931c-.091.034-.189.054-.269.109c-1.154.792-1.148 3.185.571 5.687c1.378 2.006 3.59 3.552 4.832 3.576c.026-.538-.088-1.142-.442-1.77z"
                            />
                        </svg>
                    );
                }
                break;
            case 'subst':
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                        <path fill="#e11d48" d="M9 4h6v8h4.84L12 19.84L4.16 12H9V4Z" />
                    </svg>
                );
            default:
                break;
        }
    },
    getMainEventIcon: (type, detail) => {
        switch (type.toLowerCase()) {
            case 'goal':
                if (detail === 'Own Goal') {
                    return (
                        <svg xmlns="http://www.w3.org/2000/svg" width={17} height={17} viewBox="0 0 36 36">
                            <circle cx="18" cy="18" r="18" fill="#F5F8FA" />
                            <path
                                fill="#CCD6DD"
                                d="M18 11a1 1 0 0 1-1-1V3a1 1 0 0 1 2 0v7a1 1 0 0 1-1 1zm-6.583 4.5a.99.99 0 0 1-.302-.047l-8.041-2.542a1 1 0 1 1 .603-1.907l8.042 2.542a1 1 0 0 1-.302 1.954zm13.625-.291a1.001 1.001 0 0 1-.278-1.961l6.75-1.958a1 1 0 0 1 .556 1.921l-6.75 1.958a.989.989 0 0 1-.278.04zm2.001 14.958a1 1 0 0 1-.803-.403l-5.459-7.333a1 1 0 1 1 1.604-1.194l5.459 7.333a1 1 0 0 1-.801 1.597zm-18.294-.083a1 1 0 0 1-.82-1.571l5.125-7.375a1 1 0 1 1 1.642 1.141l-5.125 7.375a.996.996 0 0 1-.822.43zM3.5 27.062c-.44 0-.844-.293-.965-.738L.347 18.262a1 1 0 1 1 1.931-.523l2.188 8.062a.998.998 0 0 1-.966 1.261zM22 34h-9a1 1 0 1 1 0-2h9a1 1 0 1 1 0 2zm10.126-6.875a.999.999 0 0 1-.971-1.239l2.125-8.625a1 1 0 1 1 1.941.479l-2.125 8.625c-.113.455-.521.76-.97.76zM30.312 7.688a.998.998 0 0 1-.5-.134L22.25 3.179a1 1 0 0 1-.364-1.367a.998.998 0 0 1 1.366-.365l7.562 4.375a1 1 0 0 1-.502 1.866zm-24.811 0a1 1 0 0 1-.584-1.813l6.188-4.438a1.001 1.001 0 0 1 1.167 1.625L6.083 7.5a1 1 0 0 1-.582.188z"
                            />
                            <path
                                fill="red"
                                d="m25.493 13.516l-7.208-5.083a1.002 1.002 0 0 0-1.161.006l-7.167 5.167a1 1 0 0 0-.375 1.091l2.5 8.583a1 1 0 0 0 .96.72H22a1 1 0 0 0 .948-.681l2.917-8.667a1 1 0 0 0-.372-1.136zM1.292 19.542a1 1 0 0 0 .872-.51l3.375-6a1 1 0 0 0 .108-.291L6.98 6.2A.999.999 0 0 0 6 5h-.428C2.145 8.277 0 12.884 0 18c0 .266.028.525.04.788l.602.514c.182.156.413.24.65.24zm9.325-16.547a.748.748 0 0 0 .553.412l6.375 1.042a.823.823 0 0 0 .243 0l6.084-1a.748.748 0 0 0 .613-.889l-.292-1.443A17.912 17.912 0 0 0 18 0c-2.425 0-4.734.486-6.845 1.356l-.521.95a.752.752 0 0 0-.017.689zm20.517 2.724l-1.504-.095a.758.758 0 0 0-.609.249a.741.741 0 0 0-.175.63l1.167 6.198a.666.666 0 0 0 .093.224c1.492 2.504 3.152 5.301 3.381 5.782c.024.084.062.079.114.151c.14.195.372.142.612.142h.007c.198 0 .323.094 1.768-.753c.001-.083.012-.164.012-.247c0-4.753-1.856-9.064-4.866-12.281zM14.541 33.376a.755.755 0 0 0-.191-.544l-4.5-5a.746.746 0 0 0-.211-.163c-5.885-3.069-5.994-3.105-6.066-3.13a.8.8 0 0 0-.242-.039c-.537 0-.695.065-1.185 2.024a18.044 18.044 0 0 0 10.644 8.703l1.5-1.333a.753.753 0 0 0 .251-.518zm17.833-8.567a.747.747 0 0 0-.592.005l-6.083 2.667a.738.738 0 0 0-.274.205l-4.25 5.083a.752.752 0 0 0 .1 1.062c.683.559 1.261 1.03 1.767 1.44a18.017 18.017 0 0 0 10.454-8.146l-.712-1.889a.749.749 0 0 0-.41-.427z"
                            />
                        </svg>
                    );
                } else if (detail === 'Missed Penalty') {
                    return (
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 48 48">
                            <path
                                fill="none"
                                stroke="#e11d48"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="4"
                                d="m6 11l5-5l13 13L37 6l5 5l-13 13l13 13l-5 5l-13-13l-13 13l-5-5l13-13L6 11Z"
                                clipRule="evenodd"
                            />
                        </svg>
                    );
                } else {
                    return <ArieteIcon></ArieteIcon>;
                }
            case 'card':
                if (detail === 'Red Card') {
                    return (
                        <div
                            style={{
                                width: '17px',
                                height: '17px',
                                background: '#e11d48',
                                borderRadius: '4px',
                            }}
                        ></div>
                    );
                } else {
                    return (
                        <div
                            style={{
                                width: '17px',
                                height: '17px',
                                background: '#ffea00',
                                borderRadius: '4px',
                            }}
                        ></div>
                    );
                }
            case 'subst':
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                        <path
                            fill="none"
                            stroke="green"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M7 10h14l-4-4"
                        />
                        <path
                            fill="none"
                            stroke="red"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M17 16H3l4 4"
                        />
                    </svg>
                );
            case 'var':
                return (
                    <div
                        style={{
                            fontSize: '8px',
                            borderRadius: '4px',
                            width: '20px',
                            height: '17px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: '#fff',
                            backgroundColor: '#e11d48',
                        }}
                    >
                        VAR
                    </div>
                );
            default:
                break;
        }
    },
};
