import { AppBar, Tab, Tabs, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import MercadoIcon from '../shared/icons/mercado-icon';
import PuntuacionIcon from '../shared/icons/puntuacion-icon';
import ArieteIcon from '../shared/icons/recommendation-icons/ariete-icon';
import PuntuacionTabComponent from './tabs/puntuacion-tab';
import MercadoTabComponent from './tabs/mercado-tab';
import PartidosTabComponent from './tabs/partidos-tab';
import NextsFixturesComponent from './tabs/next-fixtures';

const MasterPlayerTabsComponent = ({ selectedIndex, player }) => {
    const [currentTab, setCurrentTab] = useState(selectedIndex ?? 1);
    const [currentSubTab, setCurrentSubTab] = useState(0);

    const handleChange = (tabIndex) => {
        setCurrentTab(tabIndex);
    };

    const tabs = [
        {
            index: 1,
            text: 'Partidos',
            icon: <ArieteIcon width={20} height={20} />,
            subTabs: [
                {
                    index: 0,
                    text: 'Últimos partidos',
                    component: <PartidosTabComponent player={player} />,
                },
                {
                    index: 1,
                    text: 'Próximos partidos',
                    component: <NextsFixturesComponent player={player} />,
                },
            ],
        },
        {
            index: 2,
            text: 'Mercado',
            icon: <MercadoIcon />,
            component: <MercadoTabComponent player={player} />,
        },
        {
            index: 3,
            text: 'Puntuación',
            icon: <PuntuacionIcon iconWidth={25} iconHeight={25} />,
            component: <PuntuacionTabComponent player={player} />,
        },
    ];

    useEffect(() => {
        if (player?.fixtures?.lastFixtures?.length > 0) {
            setCurrentSubTab(0);
        } else {
            setCurrentSubTab(1);
        }
    }, [player]);

    return (
        <section className={`fixture-rounds-tabs`} style={{ marginBottom: '0' }}>
            <div className="af-tab-list">
                {tabs.map((tab) => (
                    <div
                        key={tab.index}
                        className={`af-tab ${currentTab === tab.index && 'af-tab-selected'}`}
                        onClick={() => handleChange(tab.index)}
                    >
                        <Typography variant="h6" className={`af-tab-text `}>
                            {tab.text}
                        </Typography>
                        {tab.icon}
                    </div>
                ))}
            </div>
            <div className="tab-content">
                {currentTab === 1 && (
                    <div>
                        <AppBar position="static" sx={{ background: '#22272f' }}>
                            <Tabs
                                value={currentSubTab}
                                onChange={(_, newValue) => setCurrentSubTab(newValue)}
                                indicatorColor="primary"
                                textColor="inherit"
                                variant="fullWidth"
                                aria-label="full width tabs example"
                            >
                                {tabs[0].subTabs.map((subTab) => (
                                    <Tab key={subTab.index} label={subTab.text} />
                                ))}
                            </Tabs>
                        </AppBar>
                        <SwipeableViews
                            axis="x"
                            index={currentSubTab}
                            onChangeIndex={(index) => setCurrentSubTab(index)}
                        >
                            {tabs[0].subTabs.map((subTab) => (
                                <div key={subTab.index}>{subTab.component}</div>
                            ))}
                        </SwipeableViews>
                    </div>
                )}

                {currentTab === 2 && <div key={tabs[1].index}>{tabs[1].component}</div>}

                {currentTab === 3 && <div key={tabs[2].index}>{tabs[2].component}</div>}
            </div>
        </section>
    );
};

export default MasterPlayerTabsComponent;
