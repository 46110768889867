import { useState, useContext, useEffect } from 'react';
import Link from 'next/link';
import PersonIcon from '@mui/icons-material/Person';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Divider } from '@mui/material';
import TwitterIcon from './icons/twitter-icon';
import TelegramIcons from './icons/telegram-icons';
import Twitch from './icons/twitch';
import Tiktok from './icons/tiktok';
import TelegramIcon from './icons/telegram-icon';
import { Rutas } from '@/constants/rutas';
import { Context } from '@/context';
import { REDUCERS } from '@/constants/reducers';
import axiosApiInstance from '@/api/shared/axios-instance';
import URL_FACTORY from '@/helpers/url-helper';
import { TwitchConstants } from '@/constants/twitch-constants';

function SocialComponent() {
    const [anchorEl, setAnchorEl] = useState(null);
    const { state, dispatch } = useContext(Context);
    const { user, configuration } = state;
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const logout = () => {
        dispatch({ type: REDUCERS.LOGGED_OUT_USER });
    };

    const getTwitchConfiguration = () => {
        if (configuration?.twitchOption === TwitchConstants.LIVE) {
            return (
                <div
                    className="twitch-live"
                    onClick={() => window.open('https://www.twitch.tv/donquijotedlc', '_blank')}
                >
                    <Twitch color="#a970ff" width={18} height={18} />

                    <p className="twitch-live__title">En directo</p>
                </div>
            );
        }

        return (
            <div className="twitch-live">
                <Twitch
                    color="#fff"
                    width={18}
                    height={18}
                    onClick={() => window.open('https://www.twitch.tv/donquijotedlc', '_blank')}
                />
            </div>
        );
    };

    useEffect(() => {
        if (!configuration) {
            setTimeout(() => {
                axiosApiInstance.get(URL_FACTORY.GetConfiguration).then((response) => {
                    dispatch({ type: REDUCERS.UPDATE_CONFIGURATION, payload: response.data });
                });
            }, 3000);
        }
    }, []);

    return (
        <div className="social-networks-container">
            <div className="new-bar">
                <div className="__left">
                    <div className="social-networks-text text-right social-networks-text-left-first-row-right">
                        <TwitterIcon
                            onClick={() => window.open('https://twitter.com/AnaliticaLaLiga', '_blank')}
                        />
                        <div
                            onClick={() => window.open('https://www.tiktok.com/@analitica.fantasy', '_blank')}
                        >
                            <Tiktok color={'#fff'} width={18} height={18} />
                        </div>
                        <TelegramIcon
                            onClick={() => window.open('https://t.me/+pOyVGp5tSEpkMzBh', '_blank')}
                        />
                        <TelegramIcons
                            onClick={() => window.open('https://t.me/+9Qf0bWVpF35jNWEx', '_blank')}
                        />
                        {getTwitchConfiguration()}
                    </div>
                </div>
                <div className="social-networks-container__right">
                    <div>
                        <div className="social-networks-text text-left social-networks-text-left-first-row-left">
                            {!user.logged ? (
                                <Link
                                    legacyBehavior
                                    href={Rutas.INICIA_SESION}
                                    style={{ color: 'rgba(250,250,250,.7)' }}
                                    className="li-item-icon"
                                >
                                    <a className="li-item inicia-sesion-menu">
                                        <PersonIcon className="fas fa-user social-network-icon twitter-icon" />
                                        <span className="social-networks-container__right__inicia-sesion">
                                            Iniciar Sesión
                                        </span>
                                    </a>
                                </Link>
                            ) : (
                                <div className="d-flex">
                                    <div className="">
                                        <Button
                                            id="nickname"
                                            aria-controls={open ? 'basic-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={open ? 'true' : undefined}
                                            onClick={handleClick}
                                            style={{ padding: '0px' }}
                                        >
                                            <span
                                                style={{
                                                    color: '#fff',
                                                    marginRight: '7px',
                                                }}
                                            >
                                                {user?.nickname || user?.email?.split('@')[0]}
                                            </span>
                                            <AccountCircleIcon
                                                className="icon-profile"
                                                style={{ fontSize: '25px' }}
                                            />
                                        </Button>
                                        <Menu
                                            id="profile"
                                            anchorEl={anchorEl}
                                            open={open}
                                            onClose={handleClose}
                                            MenuListProps={{
                                                'aria-labelledby': 'profile',
                                            }}
                                        >
                                            <Link href={Rutas.PERFIL_DE_USUARIO} passHref>
                                                <MenuItem onClick={handleClose}>
                                                    <PersonIcon style={{ marginRight: '5px' }} />
                                                    Perfil de usuario
                                                </MenuItem>
                                            </Link>
                                            <Divider style={{ backgroundColor: '#E0E0E0' }} />
                                            <MenuItem onClick={logout}>
                                                <ExitToAppIcon style={{ marginRight: '5px' }} />
                                                Cerrar sesión
                                            </MenuItem>
                                        </Menu>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SocialComponent;
