import Layout from '@/components/shared/layout';
import '@/styles/base.scss';
import { Provider } from '@/context';

export default function App({ Component, pageProps }) {
    return (
        <section className={`analitica-fantasy-site`}>
            <Provider>
                <Layout>
                    <Component {...pageProps} />
                </Layout>
            </Provider>
        </section>
    );
}
