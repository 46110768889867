import { REDUCERS } from '@/constants/reducers';

export function fantasyHistoric(state, action) {
    switch (action.type) {
        case REDUCERS.SHOW_FANTASY_HISTORIC:
            return { ...state, fantasyHistoric: action.payload };
        case REDUCERS.HIDE_FANTASY_HISTORIC:
            return { ...state, fantasyHistoric: { season: null, fantasyId: null } };
        default:
            return state;
    }
}
