import { useRouter } from 'next/router';
import CustomImageComponent from './custom/custom-image';
import { Rutas } from '@/constants/rutas';

const Logo = ({ styles, width }) => {
    const router = useRouter();

    return (
        <CustomImageComponent
            className="image-logo__image"
            src={`${process.env.NEXT_PUBLIC_IMAGES}/logos/logo.png`}
            alt="logo"
            width={width ?? 70}
            height={width ?? 70}
            priority={true}
            onClick={() => {
                router.push(Rutas.INICIO);
            }}
            style={styles}
        />
    );
};

export default Logo;
