const AdminJugadoresIcon = ({ iconWidth, iconHeight }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={iconWidth} height={iconHeight} viewBox="0 0 24 24">
            <g fill="#ffffff">
                <path d="M20.75 4.5a2.5 2.5 0 1 1-5 0a2.5 2.5 0 0 1 5 0Z" />
                <path
                    fillRule="evenodd"
                    d="m13.437 6.661l.005.003A5.334 5.334 0 0 1 16.19 9.2a3.707 3.707 0 0 0 3.316 2.049h1.245a.75.75 0 0 1 0 1.5h-1.245a5.207 5.207 0 0 1-4.658-2.878a3.834 3.834 0 0 0-1.976-1.821c-1.364-.562-1.796-.72-2.62-.66c-1.143.083-2.193.66-4.695 2.082l-1.435.815a.75.75 0 1 1-.742-1.304l1.436-.815l.172-.098c2.274-1.293 3.634-2.066 5.155-2.176c1.218-.089 1.954.215 3.295.766Zm-.132 3.835a.75.75 0 0 1-.05 1.06l-.535.478c-.28.25-.551.49-.798.724a7.19 7.19 0 0 0-.471.48a1.53 1.53 0 0 0-.146.187a1.25 1.25 0 0 0 .044.973c.042.089.13.21.363.418c.238.212.568.463 1.055.832l.075.056c.56.425.949.719 1.244 1.09c.328.413.565.89.696 1.401c.118.46.118.947.118 1.65V22a.75.75 0 0 1-1.5 0v-2.061c0-.833-.005-1.117-.07-1.37a2.25 2.25 0 0 0-.418-.841c-.163-.205-.387-.382-1.05-.884l-.024-.019a19.51 19.51 0 0 1-1.123-.888c-.297-.264-.553-.543-.72-.894a2.75 2.75 0 0 1-.096-2.14c.092-.248.27-.476.419-.647c.163-.189.364-.39.573-.587c.26-.246.565-.518.861-.782c.172-.152.34-.302.493-.442a.75.75 0 0 1 1.06.05ZM9.23 16.424a.75.75 0 0 1 .096 1.056l-1 1.201l-.097.116c-.642.771-1.113 1.338-1.771 1.646c-.658.308-1.395.308-2.4.307H2.75a.75.75 0 0 1 0-1.5h1.158c1.222 0 1.596-.017 1.913-.165c.318-.149.57-.426 1.352-1.364l1-1.201a.75.75 0 0 1 1.057-.096Z"
                    clipRule="evenodd"
                />
            </g>
        </svg>
    );
};

export default AdminJugadoresIcon;
