import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CustomImageComponent from './custom-image';
import { getTeamImageById } from '@/helpers/images-helper';
import URL_FACTORY from '@/helpers/url-helper';

export default function SelectMaterial({
    value,
    options,
    id,
    text,
    onChange,
    image,
    classes,
    disabled,
    style,
    variant = 'standard',
}) {
    if (!options) {
        return null;
    }

    return (
        <FormControl
            fullWidth
            variant={variant}
            size="small"
            className={classes}
            disabled={disabled}
            style={style}
        >
            <InputLabel id={`${id}-label`}>{text}</InputLabel>
            <Select labelId={`${id}-label`} id={id} value={value} label={text} onChange={onChange}>
                {options?.map((option, index) => {
                    return (
                        <MenuItem key={index} value={option.value}>
                            {image && option.value !== 'all' && (
                                <CustomImageComponent
                                    alt={'opción'}
                                    width={image.width}
                                    height={image.height}
                                    src={
                                        isNaN(option.value)
                                            ? URL_FACTORY.GetTeamImageLogo(option.value)
                                            : getTeamImageById(option.value)
                                    }
                                    style={{ marginRight: '7px' }}
                                />
                            )}
                            {option.icon} {option.label}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
}
