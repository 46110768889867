import { useContext } from 'react';
import { useRouter } from 'next/router';
import { Typography } from '@mui/material';
import CustomImageComponent from '@/components/shared/custom/custom-image';
import { translate } from '@/data/fixture-transalations';
import { getCompetitionImage } from '@/helpers/leagues-helper';
import { getTeamById } from '@/data/teams';
import URL_FACTORY from '@/helpers/url-helper';
import { Context } from '@/context';
import { Rutas } from '@/constants/rutas';
import { REDUCERS } from '@/constants/reducers';
import { getTimeShortWithMinutes } from '@/helpers/datetime-helper';

const NextsFixturesComponent = ({ player }) => {
    const fixtures = player?.fixtures?.upcomingFixtures;
    const { dispatch } = useContext(Context);
    const router = useRouter();

    const getTeamImage = (team) => {
        const teamFound = getTeamById(team?.id);

        const src = teamFound ? URL_FACTORY.GetTeamImageLogo(teamFound?.slug) : team.image;

        return (
            <CustomImageComponent
                style={{ marginRight: '4px' }}
                src={src}
                alt={team.name}
                width={18}
                height={18}
            />
        );
    };

    const redirectToFixture = (fixture) => {
        dispatch({ type: REDUCERS.HIDE_PLAYER_PROFILE });
        const url = Rutas.PARTIDO + '/' + fixture.id + '/alineaciones-probables';
        router.push(url);
    };

    return (
        <div className="partidos-tab">
            <div className="partidos-tab__header">
                <Typography className="partidos-tab__header-1">Partido</Typography>
                <Typography className="partidos-tab__header-2">Fecha</Typography>
            </div>
            {fixtures?.map((fixture, index) => {
                return (
                    <div
                        key={index}
                        className="partidos-tab__fixture"
                        onClick={() => redirectToFixture(fixture)}
                    >
                        <div className="partidos-tab__body">
                            <div className="partidos-tab__column partidos-tab__column-first">
                                <CustomImageComponent
                                    src={getCompetitionImage(fixture?.league?.id ?? fixture?.leagueId, 15)}
                                    alt="competición"
                                    width={15}
                                    height={15}
                                />
                                <Typography style={{ fontSize: '12px', color: '#0070f3', marginTop: '2px' }}>
                                    {translate(fixture?.round, true)}
                                </Typography>
                            </div>
                            <div className="partidos-tab__column partidos-tab__column-second">
                                <div className="partidos-tab__column-row">
                                    {getTeamImage(fixture?.home)}
                                    <Typography className="partidos-tab__score">
                                        {fixture?.home?.goals}
                                    </Typography>
                                </div>
                                <div className="partidos-tab__column-row">
                                    {getTeamImage(fixture?.away)}
                                    <Typography className="partidos-tab__score">
                                        {fixture?.away?.goals}
                                    </Typography>
                                </div>
                            </div>
                            <div className="partidos-tab__events">
                                <Typography>{getTimeShortWithMinutes(fixture?.date)}</Typography>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default NextsFixturesComponent;
