export const SUBSCRIPTION_TYPE = {
    FREE: 0,
    BASIC: 1,
    PREMIUM: 2,
    SUPERADMIN: 3,
};

export const subscriptions = [
    {
        value: -1,
        label: 'Todas',
    },
    {
        value: SUBSCRIPTION_TYPE.FREE,
        label: 'Free',
    },
    {
        value: SUBSCRIPTION_TYPE.BASIC,
        label: 'Basic',
    },
    {
        value: SUBSCRIPTION_TYPE.PREMIUM,
        label: 'Premium',
    },
    {
        value: SUBSCRIPTION_TYPE.SUPERADMIN,
        label: 'Superadmin',
    },
];

export const getSubscriptionLabel = (subscriptionType) => {
    const subscription = subscriptions.find((x) => x.value === subscriptionType);
    return subscription ? subscription.label : '';
};
