const PremiosYSorteosIcon = ({ width, height, color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width ?? 25} height={height ?? 25} viewBox="0 0 14 14">
            <path
                fill="none"
                stroke={color ?? '#fff'}
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12.276 12.235V6.393H1.724v5.842a1 1 0 0 0 1 1h8.552a1 1 0 0 0 1-1M7 13.234V6.391m6.138-1.599v.6a1 1 0 0 1-1 1H1.862a1 1 0 0 1-1-1v-.6a1 1 0 0 1 1-1h10.276a1 1 0 0 1 1 1M7 3.792C7 2.917 5.98.875 4.448.875c-2.491 0-1.584 2.917-.391 2.917m2.943 0C7 2.917 8.02.875 9.552.875c2.491 0 1.584 2.917.391 2.917"
            />
        </svg>
    );
};

export default PremiosYSorteosIcon;
