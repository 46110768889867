import MenuIcon from '@mui/icons-material/Menu';
import CalendarIcon from './icons/calendar-icon';
import Logo from './logo';

const MobileNavigationComponent = ({ handleHamburguerMenu, handlePartidosMenu }) => {
    return (
        <div className="mobile-navigation-container">
            <div className="mobile-navigation-container__menu-icon">
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    onClick={handleHamburguerMenu}
                >
                    <MenuIcon style={{ color: '#fff', cursor: 'pointer' }} />
                    <span style={{ color: '#fff', fontSize: '14px', cursor: 'pointer' }}>Menú</span>
                </div>
            </div>
            <div className="mobile-navigation-container__logo" style={{ cursor: 'pointer' }}>
                <Logo />
            </div>
            <div className="mobile-navigation-container__partidos-icon" onClick={handlePartidosMenu}>
                <div
                    style={{
                        textAlign: 'center',
                        cursor: 'pointer',
                    }}
                >
                    <CalendarIcon iconWidth={30} iconHeight={30} />
                    <p className="icon-partidos-drawer-text">Partidos</p>
                </div>
            </div>
        </div>
    );
};

export default MobileNavigationComponent;
