import { useRouter } from 'next/router';
import { useContext, useEffect } from 'react';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import HomeIcon from '@mui/icons-material/Home';
import { Typography } from '@mui/material';
import CustomImageComponent from '../shared/custom/custom-image';
import { Context } from '@/context';
import { REDUCERS } from '@/constants/reducers';
import { translate } from '@/data/fixture-transalations';
import { getDate_dd_MM } from '@/helpers/datetime-helper';
import { getCssClass } from '@/helpers/fixtures-helper';
import URL_FACTORY from '@/helpers/url-helper';
import { getCompetitionImage } from '@/helpers/leagues-helper';
import { Rutas } from '@/constants/rutas';

const NextRivalsTabsComponent = ({ player }) => {
    const router = useRouter();
    const { state, dispatch } = useContext(Context);
    const { preferences } = state;

    const getRival = (fixture, index, _, lastFixtures, isLastFixture) => {
        const rival = fixture?.home?.id === player?.team?.id ? fixture?.away : fixture?.home;
        const styles = { fontSize: '18px' };

        const icon =
            fixture?.home?.id === player?.team?.id ? (
                <HomeIcon style={styles} />
            ) : (
                <FlightTakeoffIcon style={styles} />
            );

        let src = rival?.image;

        if (rival?.slug) {
            src = URL_FACTORY.GetTeamImageLogo(rival?.slug, 25);
        }

        const cssClass = getCssClass(fixture);

        return (
            <div
                style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                className="clickable-element"
                onClick={() => {
                    dispatch({
                        type: REDUCERS.HIDE_PLAYER_PROFILE,
                        payload: {
                            fixtureId: fixture.id,
                        },
                    });
                    router.push(`${Rutas.PARTIDO}/${fixture.id}/alineaciones-probables`);
                }}
                key={index}
            >
                <Typography variant="div">
                    <Typography style={{ fontSize: '12px' }}>{getDate_dd_MM(fixture?.date)}</Typography>
                </Typography>

                <Typography
                    style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        marginLeft: '8px',
                        paddingLeft: '6px',
                        paddingRight: '6px',
                        borderRight: isLastFixture ? 'none' : '2px solid #ccc',
                    }}
                    variant="div"
                >
                    <CustomImageComponent
                        key={index}
                        src={src}
                        width={25}
                        height={25}
                        alt={rival.name}
                    ></CustomImageComponent>
                    {icon}
                </Typography>
                <Typography style={{ display: 'flex', marginTop: '4px', alignItems: 'center' }}>
                    <CustomImageComponent
                        src={getCompetitionImage(fixture?.leagueId, 15)}
                        width={15}
                        height={15}
                        alt="competición"
                        title="competición"
                        style={{ marginRight: '4px' }}
                    />
                    <Typography style={{ fontSize: '14px' }}>{translate(fixture.round, true)}</Typography>
                </Typography>

                {lastFixtures && (
                    <Typography
                        className={cssClass}
                        style={{ display: 'flex', padding: '0 4px', borderRadius: '4px' }}
                    >
                        <span style={{ fontSize: '12px' }}>{fixture?.home?.goals}</span>
                        <span style={{ fontSize: '12px' }}>-</span>
                        <span style={{ fontSize: '12px' }}>{fixture?.away?.goals}</span>
                    </Typography>
                )}
            </div>
        );
    };

    const getNextRivals = (fixtures, last) => {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: '12px',
                    fontSize: '14px',
                    border: '1px solid rgba(0, 0, 0, .1)',
                    marginTop: '4px',
                    padding: '4px',
                    borderRadius: '4px',
                }}
            >
                <div
                    className="player-info-card__position"
                    style={{
                        display: 'flex',
                        marginBottom: '12px',
                        paddingTop: '12px',
                        justifyContent: 'center',
                    }}
                >
                    {fixtures
                        ?.sort((a, b) => new Date(a.date) - new Date(b.date))
                        ?.map((fixture, index) =>
                            getRival(fixture, index, index === 0, last, index === fixtures.length - 1)
                        )}
                </div>
            </div>
        );
    };

    const handleChange = (newValue) => {
        dispatch({
            type: REDUCERS.UPDATE_PREFERENCES,
            payload: {
                ultimosRivales: newValue,
            },
        });
    };

    useEffect(() => {
        if (!preferences.proximosRivales) {
            handleChange(2);
        }
    }, []);

    const tabs = [
        { index: 1, text: 'Últimos rivales' },
        { index: 2, text: 'Próximos rivales' },
    ];

    return (
        <>
            <div className="af-tab-list af-secondary-tab-list">
                {tabs.map((tab) => (
                    <div
                        key={tab.index}
                        className={`af-tab ${preferences.ultimosRivales === tab.index && 'af-tab-selected'}`}
                        onClick={() => handleChange(tab.index)}
                        style={{ flexDirection: 'row' }}
                    >
                        <Typography className={`af-tab-text `}>{tab.text}</Typography>
                    </div>
                ))}
            </div>
            {preferences.ultimosRivales === 2 && (
                <div>
                    {getNextRivals(
                        player?.fixtures?.upcomingFixtures
                            ?.sort((a, b) => new Date(a.date) - new Date(b.date))
                            ?.filter((f, i) => i < 5),
                        false
                    )}
                </div>
            )}
            {preferences.ultimosRivales === 1 && (
                <div>
                    {getNextRivals(
                        player?.fixtures?.lastFixtures
                            ?.sort((a, b) => new Date(b.date) - new Date(a.date))
                            ?.filter((f, i) => i < 5),
                        true
                    )}
                </div>
            )}
        </>
    );
};

export default NextRivalsTabsComponent;
