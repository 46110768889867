import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import DangerousIcon from '@mui/icons-material/Dangerous';
import BrightnessMediumIcon from '@mui/icons-material/BrightnessMedium';
import GppGoodIcon from '@mui/icons-material/GppGood';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import { FIXTURE_STATUS } from '@/constants/fixture';
import { LEAGUES } from '@/constants/leagues';

export const getScoreByFixtureStatus = function (score, fixtureStatus) {
    switch (fixtureStatus) {
        case FIXTURE_STATUS.SECOND_HALF:
        case FIXTURE_STATUS.FIRST_HALF:
        case FIXTURE_STATUS.HALF_TIME:
        case FIXTURE_STATUS.EXTRA_TIME:
        case FIXTURE_STATUS.PENALTIES:
        case FIXTURE_STATUS.MATCH_FINISHED:
        case FIXTURE_STATUS.MATCH_FINISHED_AFTER_EXTRA_TIME:
        case FIXTURE_STATUS.LIVE:
        case FIXTURE_STATUS.MATCH_FINISHED_AFTER_PENALTIES:
            return score;
        default:
            return '-';
    }
};

export const getScoreByFixtureStatusLiveFixture = function (score, fixtureStatus) {
    switch (fixtureStatus) {
        case FIXTURE_STATUS.SECOND_HALF:
        case FIXTURE_STATUS.FIRST_HALF:
        case FIXTURE_STATUS.HALF_TIME:
        case FIXTURE_STATUS.EXTRA_TIME:
        case FIXTURE_STATUS.PENALTIES:
        case FIXTURE_STATUS.MATCH_FINISHED:
        case FIXTURE_STATUS.MATCH_FINISHED_AFTER_EXTRA_TIME:
        case FIXTURE_STATUS.LIVE:
        case FIXTURE_STATUS.MATCH_FINISHED_AFTER_PENALTIES:
            return score;
        default:
            return '-';
    }
};

export const getDisplayLiveIndicator = (fixtureStatus) => {
    switch (fixtureStatus) {
        case FIXTURE_STATUS.SECOND_HALF:
        case FIXTURE_STATUS.FIRST_HALF:
        case FIXTURE_STATUS.HALF_TIME:
        case FIXTURE_STATUS.EXTRA_TIME:
        case FIXTURE_STATUS.BREAK_TIME:
        case FIXTURE_STATUS.PENALTIES:
        case FIXTURE_STATUS.MATCH_INTERRUPTED:
        case FIXTURE_STATUS.LIVE:
            return true;
        default:
            return false;
    }
};

export const getFixtureStatusClass = function (fixtureStatus) {
    switch (fixtureStatus) {
        case FIXTURE_STATUS.TO_BE_DEFINED:
            return '';
        case FIXTURE_STATUS.NOT_STARTED:
            return 'clickable-text-link';
        default:
            return 'clickable-text-link';
    }
};

export const getFixtureStatusCard = function (fixtureStatus, lineupsAvailable, leagueId) {
    switch (fixtureStatus) {
        case FIXTURE_STATUS.TO_BE_DEFINED:
            return 'Por definir';
        case FIXTURE_STATUS.NOT_STARTED:
            if (leagueId !== LEAGUES.LA_LIGA) {
                return 'Previa';
            }
            return lineupsAvailable ? 'Alineaciones' : 'Alineaciones';
        case FIXTURE_STATUS.SECOND_HALF:
            return 'Segunda mitad';
        case FIXTURE_STATUS.FIRST_HALF:
            return 'Primera mitad';
        case FIXTURE_STATUS.HALF_TIME:
            return 'Entretiempo';
        case FIXTURE_STATUS.EXTRA_TIME:
            return 'Tiempo extra';
        case FIXTURE_STATUS.BREAK_TIME:
            return 'Tiempo extra';
        case FIXTURE_STATUS.MATCH_SUSPENDED:
            return 'Suspendido';
        case FIXTURE_STATUS.MATCH_CANCELED:
            return 'Cancelado';
        case FIXTURE_STATUS.TECHNICAL_LOSS:
            return 'Derrota técnica';
        case FIXTURE_STATUS.MATCH_ABANDONED:
            return 'Abandono';
        case FIXTURE_STATUS.MATCH_POSTPONED:
            return 'Pospuesto';
        case FIXTURE_STATUS.MATCH_INTERRUPTED:
            return 'Interrumpido';
        case FIXTURE_STATUS.PENALTIES:
            return 'Penaltis';
        case FIXTURE_STATUS.MATCH_FINISHED:
        case FIXTURE_STATUS.MATCH_FINISHED_AFTER_EXTRA_TIME:
        case FIXTURE_STATUS.MATCH_FINISHED_AFTER_PENALTIES:
            return 'Fin';
        case FIXTURE_STATUS.MATCH_POSTPONED:
            return 'Pospuesto';
        case FIXTURE_STATUS.LIVE:
            return 'En directo';
        default:
            return fixtureStatus;
    }
};

export const getFixtureDrawerName = (round) => {
    switch (round.leagueId) {
        case LEAGUES.CHAMPIONS_LEAGUE:
            if (round.roundNumber === 30) {
                return '1/8';
            } else if (round.roundNumber === 31) {
                return '1/4';
            } else if (round.roundNumber === 32) {
                return '1/2';
            } else if (round.roundNumber === 33) {
                return 'F';
            } else if (round.roundNumber === 0) {
                return 'PO';
            } else if (round.roundNumber === -4) {
                return 'PR';
            }
        case LEAGUES.EUROPA_LEAGUE:
            if (round.roundNumber === 30) {
                return '1/8';
            } else if (round.roundNumber === 31) {
                return '1/4';
            } else if (round.roundNumber === 32) {
                return '1/2';
            } else if (round.roundNumber === 33) {
                return 'F';
            } else if (round.roundNumber === 0) {
                return 'PO';
            } else if (round.roundNumber === -4) {
                return 'PR';
            } else if (round.roundNumber === 7) {
                return '1/32';
            }
        case LEAGUES.COPA_DEL_REY:
            if (round.roundNumber === 30) {
                return '1/8';
            } else if (round.roundNumber === 31) {
                return '1/4';
            } else if (round.roundNumber === 32) {
                return '1/2';
            } else if (round.roundNumber === 33) {
                return 'F';
            } else if (round.roundNumber === 0) {
                return 'PO';
            } else if (round.roundNumber === -4) {
                return 'PR';
            }
        default:
            if (round?.roundNumber < 0) {
                return `J${round?.roundNumber * -1}`;
            }
            return `J${round?.roundNumber}`;
    }
};

export const getFixtureScore = function (score, fixtureStatus) {
    switch (fixtureStatus) {
        case FIXTURE_STATUS.TO_BE_DEFINED:
        case FIXTURE_STATUS.NOT_STARTED:
        case FIXTURE_STATUS.MATCH_SUSPENDED:
        case FIXTURE_STATUS.MATCH_POSTPONED:
            return '-';
        case FIXTURE_STATUS.SECOND_HALF:
        case FIXTURE_STATUS.FIRST_HALF:
        case FIXTURE_STATUS.HALF_TIME:
        case FIXTURE_STATUS.EXTRA_TIME:
        case FIXTURE_STATUS.BREAK_TIME:
        case FIXTURE_STATUS.MATCH_CANCELED:
        case FIXTURE_STATUS.MATCH_ABANDONED:
        case FIXTURE_STATUS.TECHNICAL_LOSS:
        case FIXTURE_STATUS.WALKOVER:
        case FIXTURE_STATUS.MATCH_POSTPONED:
        case FIXTURE_STATUS.MATCH_INTERRUPTED:
        case FIXTURE_STATUS.PENALTIES:
        case FIXTURE_STATUS.MATCH_FINISHED:
        case FIXTURE_STATUS.MATCH_FINISHED_AFTER_EXTRA_TIME:
        case FIXTURE_STATUS.MATCH_FINISHED_AFTER_PENALTIES:
        case FIXTURE_STATUS.LIVE:
            return score ?? 0;
        default:
            return '-';
    }
};

export const getFixtureStatus = function (fixtureStatus) {
    switch (fixtureStatus) {
        case FIXTURE_STATUS.TO_BE_DEFINED:
            return 'Por definir horario';
        case FIXTURE_STATUS.NOT_STARTED:
            return 'Ver previa';
        case FIXTURE_STATUS.SECOND_HALF:
            return 'Segunda mitad';
        case FIXTURE_STATUS.FIRST_HALF:
            return 'Primera mitad';
        case FIXTURE_STATUS.HALF_TIME:
            return 'Entretiempo';
        case FIXTURE_STATUS.EXTRA_TIME:
            return 'Tiempo extra';
        case FIXTURE_STATUS.BREAK_TIME:
            return 'Entretiempo (tiempo extra)';
        case FIXTURE_STATUS.MATCH_SUSPENDED:
            return 'Suspendido';
        case FIXTURE_STATUS.MATCH_CANCELED:
            return 'Cancelado';
        case FIXTURE_STATUS.TECHNICAL_LOSS:
            return 'Derrota técnica';
        case FIXTURE_STATUS.MATCH_ABANDONED:
            return 'Abandono';
        case FIXTURE_STATUS.MATCH_POSTPONED:
            return 'Pospuesto';
        case FIXTURE_STATUS.MATCH_INTERRUPTED:
            return 'Interrumpido';
        case FIXTURE_STATUS.PENALTIES:
            return 'Penaltis';
        case FIXTURE_STATUS.MATCH_FINISHED:
            return 'Finalizado';
        case FIXTURE_STATUS.MATCH_FINISHED_AFTER_EXTRA_TIME:
            return 'Finalizado (tiempo extra)';
        case FIXTURE_STATUS.MATCH_FINISHED_AFTER_PENALTIES:
            return 'Finalizado (penaltis)';
        case FIXTURE_STATUS.MATCH_POSTPONED:
            return 'Pospuesto';
        case FIXTURE_STATUS.LIVE:
            return 'En directo';
        default:
            return fixtureStatus;
    }
};

export const isMatchInProgress = (fixtureStatus) => {
    switch (fixtureStatus) {
        case FIXTURE_STATUS.FIRST_HALF:
        case FIXTURE_STATUS.SECOND_HALF:
        case FIXTURE_STATUS.HALF_TIME:
        case FIXTURE_STATUS.EXTRA_TIME:
        case FIXTURE_STATUS.BREAK_TIME:
        case FIXTURE_STATUS.MATCH_INTERRUPTED:
        case FIXTURE_STATUS.PENALTIES:
        case FIXTURE_STATUS.LIVE:
            return true;
        default:
            return false;
    }
};

export const getFixtureElapsed = (fixtureElapsed, fixtureStatus) => {
    const isMatchInProgressValue = isMatchInProgress(fixtureStatus);

    if (isMatchInProgressValue && fixtureElapsed) {
        return ` - min ${fixtureElapsed}`;
    } else {
        return ``;
    }
};

export const getColorScore = function (number) {
    if (!number) {
        return 'score-missing';
    }
    var score = parseInt(number.weekPoints);

    if (score === null || score === undefined) {
        return 'score-missing';
    }

    if (score <= 2) {
        return 'score-very-low';
    } else if (score <= 4) {
        return 'score-low';
    } else if (score <= 7) {
        return 'score-high';
    } else {
        return 'score-very-high';
    }
};

export const getFixtureColorRisk = function (number) {
    if (!number && number !== 0) {
        return '';
    }
    if (number <= 30) {
        return 'risk-very-high';
    } else if (number < 45) {
        return 'risk-high';
    } else if (number <= 55) {
        return 'risk-moderate';
    } else if (number <= 70) {
        return 'risk-low';
    } else {
        return 'risk-very-low';
    }
};

export const getFixtureBackgroundColorRisk = function (number, colors, isWhiteBackground) {
    if (!number || number === 0 || number === 'NaN') {
        return '';
    }
    if (number <= 30) {
        return colors.riskVeryHigh;
    } else if (number < 45) {
        return colors.riskHigh;
    } else if (number <= 55) {
        return isWhiteBackground ? colors.riskMedium : colors.riskMedium;
    } else if (number <= 70) {
        return colors.riskLow;
    } else {
        return colors.riskVeryLow;
    }
};

export const getFixtureRisk = (number) => {
    if ((!number && number !== 0) || number === 'NaN') {
        return '-';
    }
    if (number <= 30) {
        return 'Muy alto';
    } else if (number < 45) {
        return 'Alto';
    } else if (number <= 55) {
        return 'Medio';
    } else if (number <= 70) {
        return 'Bajo';
    } else {
        return 'Muy bajo';
    }
};

export const probabilityHighScore = (number) => {
    if (!number && number !== 0) {
        return '-';
    }
    if (number <= 30) {
        return 'Muy Alta';
    } else if (number < 45) {
        return 'Alta';
    } else if (number <= 55) {
        return 'Media';
    } else if (number <= 70) {
        return 'Baja';
    } else {
        return 'Muy baja';
    }
};

export const getNextRivalIcon = (number) => {
    if (!number && number !== 0) {
        return '-';
    }
    if (number <= 30) {
        return (
            <>
                <DangerousIcon
                    style={{
                        color: '#be1212',
                        fontSize: '16px',
                        marginRight: '3px',
                        position: 'relative',
                        bottom: '1px',
                    }}
                />
                <span>Muy Alta</span>
            </>
        );
    } else if (number < 45) {
        return (
            <>
                <WarningAmberIcon
                    style={{
                        color: 'rgb(255, 167, 38)',
                        fontSize: '16px',
                        marginRight: '3px',
                        position: 'relative',
                        bottom: '1px',
                    }}
                />
                <span>Alta</span>
            </>
        );
    } else if (number <= 55) {
        return (
            <>
                <BrightnessMediumIcon
                    style={{
                        color: '#000',
                        fontSize: '16px',
                        marginRight: '3px',
                        position: 'relative',
                        bottom: '1px',
                    }}
                />
                <span>Media</span>
            </>
        );
    } else if (number <= 70) {
        return (
            <>
                <HealthAndSafetyIcon
                    style={{
                        color: '#1a892d',
                        fontSize: '16px',
                        marginRight: '3px',
                        position: 'relative',
                        bottom: '1px',
                    }}
                />
                <span>Baja</span>
            </>
        );
    } else {
        return (
            <>
                <GppGoodIcon
                    style={{
                        color: '#1d6fb1',
                        fontSize: '16px',
                        marginRight: '3px',
                        position: 'relative',
                        bottom: '1px',
                    }}
                />
                <span>Muy baja</span>
            </>
        );
    }
};

export const clearRound = (round) => {
    if (!round) return '-';
    return round.replace('Regular Season -', 'Jornada').replace('Group', 'Grupo');
};

export const clearRoundShort = (round) => {
    return round.replace('Regular Season -', 'J');
};

export const getAllFixtureRounds = () => {
    const arr = [];
    for (let i = 1; i <= 38; i++) {
        arr.push({
            label: 'Jornada ' + i,
            value: 'Regular Season - ' + i,
        });
    }

    return arr;
};

export const clearRefereeName = (refereeName) => {
    if (refereeName?.indexOf(',') === -1) {
        return refereeName;
    }

    return refereeName?.split(',')?.[0];
};

export const clearLeagueName = (leagueName) => {
    if (!leagueName) {
        return '';
    }

    return leagueName;
};

export const getCssClass = (fixture) => {
    switch (fixture.gameResult) {
        case 'win':
            return 'game-green';
        case 'lose':
            return 'game-red';
        case 'draw':
            return 'game-yellow';
        default:
            return '';
    }
};
