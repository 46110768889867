import https from 'https';
import axios, { HttpStatusCode } from 'axios';
import { getFromLocalStorage, removeFromLocalStorage } from '@/helpers/cookies';
import { SITE_USER_COOKIE } from '@/constants/site';
const axiosApiInstance = axios.create();

axiosApiInstance.defaults.httpsAgent = new https.Agent({
    rejectUnauthorized: false,
});

axiosApiInstance.interceptors.request.use(
    async (config) => {
        const userJSON = getFromLocalStorage(SITE_USER_COOKIE);

        if (userJSON) {
            const user = JSON.parse(userJSON);
            const { token, email } = user;

            if (token) {
                config.headers = {
                    ...config.headers,
                    Authorization: `Bearer ${token}`,
                    af_email: `${email}`,
                };
            }
        }

        return config;
    },
    (error) => {
        if (error.response.status === HttpStatusCode.Unauthorized) {
            removeFromLocalStorage(SITE_USER_COOKIE);
            window.location.href = Rutas.INICIO;
        }

        Promise.reject(error);
    }
);

export default axiosApiInstance;
