import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';

const MasterPlayerHeaderComponent = ({ handleClose, player }) => {
    return (
        <section className="master-player-header">
            <div>
                <Typography variant="h6">{player?.information?.name}</Typography>
            </div>
            <IconButton aria-label="close" onClick={handleClose}>
                <CloseIcon style={{ color: '#fff' }} />
            </IconButton>
        </section>
    );
};

export default MasterPlayerHeaderComponent;
