import LocalPlayIcon from '@mui/icons-material/LocalPlay';
import AlineacionesActivityIcon from '../home/icons/alineaciones-activity-icon';
import RachasIcon from './icons/rachas-icon';
import AlineacionesManagersIcon from './icons/alineaciones-manager-icon';
import PredictingRankingIcon from './icons/predicting-ranking-icon';
import CrearAlineacionesIcon from './icons/crear-alineaciones-icon';
import PredictingManagerRulesIcon from './icons/predicting-manager-rules-icon';
import ComparadorFantasyIcon from './icons/comparador-fantasy-icon';
import AdminIcon from './icons/admin-icon';
import AdminSyncIcon from './icons/admin-sync-icon';
import AdminJugadoresIcon from './icons/admin-jugadores-icon';
import NewsIcon from './icons/news-icon';
import MercadoIcon from './icons/mercado-icon';
import PlantillasIcon from './icons/plantillas-icon';
import MisPlantillasIcon from './icons/mis-plantillas-icon';
import AnalizaTuPlantillaIcon from './icons/analiza-tu-plantilla-icon';
import ColaboradoresIcon from './icons/colaboradores-icon';
import AdministradorParteMedicoIcon from './icons/administrador-parte-medico-icon';
import AdministradorImagenesIcon from './icons/administrador-imagenes-icon';
import FantasyLaLigaIcon from './icons/fantasy-la-liga-icon';
import FantasyLaLigaEstadisticasIcon from './icons/fantasy-la-liga-estadisticas-icon';
import FantasyLaLigaJugadoresIcon from './icons/fantasy-la-liga-jugadores-icon';
import PredictingManagerIcon from './icons/predicting-manager-icon';
import QuinielaAnaliticaIcon from './icons/quiniela-analitica-icon';
import VoteIcon from './icons/vote-icon';
import NoticiasFlashIcon from './icons/noticias-flash-icon';
import FullCalendarIcon from './icons/full-calendar';
import InfoPlayerIcon from './icons/info-player-icon';
import ConfigurationIcon from './icons/configuration-icon';
import ConvocatoriasIcon from './icons/convocatorias-icon';
import VirusFifaIcon from './icons/virus-fifa-icon';
import MercadoFichajesLive from './icons/mercado-fichajes-live';
import BiwengerIcon from './icons/biwenger-icon';
import ClasificacionNavIcon from './icons/clasificacion-nav-icon';
import PremiosYSorteosIcon from './icons/premios-y-sorteos-icon';
import SalonDeLaFamaIcon from './icons/salon-de-la-fama';
import ActivityEventsIcon from './icons/activity-icon';
import LideresEstadisticasIcon from './icons/lideres-estadisticas-icon';
import CalendarioFantasyAdminIcon from './icons/calendario-fantasy-admin';
import LanzadoresPenaltisIcon from './icons/lanzadores-penaltis-icon';
import PujaIdealIcon from './icons/puja-idea-icon';
import ChollosIcon from './icons/recommendation-icons/chollos-icon';
import InfoIcon from './icons/info-icon';
import LesionadosIcon from './icons/lesionados-icon';
import SancionadosIcon from './icons/sancionados-icon';
import DudaStatusIcon from './icons/status-icons/duda-status-icon';
import ApercibidosStatusIcon from './icons/apercibidos-status-icon';

const getIcon = (id, width, height) => {
    const iconWidth = width ? width : 30;
    const iconHeight = height ? height : 30;

    switch (id) {
        case 'inicio':
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width={'25'} height={'25'} viewBox="0 0 48 48">
                    <path fill="#E8EAF6" d="M42 39H6V23L24 6l18 17z" />
                    <path fill="#C5CAE9" d="m39 21l-5-5V9h5zM6 39h36v5H6z" />
                    <path fill="#B71C1C" d="M24 4.3L4 22.9l2 2.2L24 8.4l18 16.7l2-2.2z" />
                    <path fill="#D84315" d="M18 28h12v16H18z" />
                    <path fill="#01579B" d="M21 17h6v6h-6z" />
                    <path
                        fill="#FF8A65"
                        d="M27.5 35.5c-.3 0-.5.2-.5.5v2c0 .3.2.5.5.5s.5-.2.5-.5v-2c0-.3-.2-.5-.5-.5"
                    />
                </svg>
            );
        case 'administrador-once-tipo':
            return <AlineacionesActivityIcon iconWidth={iconWidth} iconHeight={iconHeight} color={'#fff'} />;
        case 'players-status':
            return <InfoPlayerIcon iconWidth={iconWidth} iconHeight={iconHeight} />;
        case 'biwenger':
            return <BiwengerIcon width={iconWidth} height={iconHeight} />;
        case 'admin':
            return <AdminIcon iconWidth={iconWidth} iconHeight={iconHeight} />;
        case 'admin-sync-data':
            return <AdminSyncIcon iconWidth={iconWidth} iconHeight={iconHeight} />;
        case 'admin-noticias-flash':
        case 'noticias-flash':
            return <NoticiasFlashIcon />;
        case 'administrador-jugadores':
            return <AdminJugadoresIcon iconWidth={iconWidth} iconHeight={iconHeight} />;
        case 'noticias':
            return <NewsIcon iconWidth={iconWidth} iconHeight={iconHeight} />;
        case 'mercado':
        case 'mercado-biwenger':
            return <MercadoIcon />;
        case 'estadisticas-biwenger':
            return <FantasyLaLigaEstadisticasIcon />;
        case 'plantillas':
            return <PlantillasIcon iconWidth={iconWidth} iconHeight={iconHeight} />;
        case 'mis-plantillas':
            return <MisPlantillasIcon />;
        case 'analiza-tu-plantilla':
            return <AnalizaTuPlantillaIcon iconWidth={'25'} iconHeight={'25'} />;
        case 'lideres-estadisticas':
            return <LideresEstadisticasIcon iconHeight={'25'} iconWidth={'25'} />;
        case 'alineaciones-probables':
            return <AlineacionesActivityIcon />;
        case 'colaboradores':
            return <ColaboradoresIcon iconWidth={iconWidth} iconHeight={iconHeight} />;
        case 'lanzadores-penaltis':
            return <LanzadoresPenaltisIcon width={iconWidth} height={iconHeight} />;
        case 'administrador-parte-medico':
            return <AdministradorParteMedicoIcon iconWidth={iconWidth} iconHeight={iconHeight} />;
        case 'administrador-imagenes':
            return <AdministradorImagenesIcon iconWidth={iconWidth} iconHeight={iconHeight} />;
        case 'fantasy-la-liga':
            return <FantasyLaLigaIcon iconWidth={iconWidth} iconHeight={iconHeight} />;
        case 'fantasy-la-liga-estadisticas':
            return <FantasyLaLigaEstadisticasIcon />;
        case 'administrador-calendario-admin':
            return <CalendarioFantasyAdminIcon />;
        case 'fantasy-la-liga-estadisticas-jugadores':
            return <FantasyLaLigaJugadoresIcon iconWidth={iconWidth} iconHeight={iconHeight} />;
        case 'fantasy-la-liga-comparador':
            return <ComparadorFantasyIcon iconWidth={iconWidth} iconHeight={iconHeight} />;
        case 'fantasy-la-liga-rachas':
            return <RachasIcon color={'#fff'} />;
        case 'administrador-de-partidos':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={iconWidth}
                    height={iconHeight}
                    viewBox="0 0 15 15"
                >
                    <path
                        fill="#fff"
                        fillRule="evenodd"
                        d="M1.903 7.297c0 3.044 2.207 5.118 4.686 5.547a.521.521 0 1 1-.178 1.027C3.5 13.367.861 10.913.861 7.297c0-1.537.699-2.745 1.515-3.663c.585-.658 1.254-1.193 1.792-1.602H2.532a.5.5 0 0 1 0-1h3a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0V2.686l-.001.002c-.572.43-1.27.957-1.875 1.638c-.715.804-1.253 1.776-1.253 2.97m11.108.406c0-3.012-2.16-5.073-4.607-5.533a.521.521 0 1 1 .192-1.024c2.874.54 5.457 2.98 5.457 6.557c0 1.537-.699 2.744-1.515 3.663c-.585.658-1.254 1.193-1.792 1.602h1.636a.5.5 0 1 1 0 1h-3a.5.5 0 0 1-.5-.5v-3a.5.5 0 1 1 1 0v1.845h.002c.571-.432 1.27-.958 1.874-1.64c.715-.803 1.253-1.775 1.253-2.97"
                        clipRule="evenodd"
                    />
                </svg>
            );
        case 'fantasy-la-liga-calendario':
            return <CalendarioFantasyAdminIcon />;
        case 'predicting-manager-lineups':
            return <CrearAlineacionesIcon />;
        case 'predicting-manager-ranking':
        case 'quiniela-clasificacion':
            return <PredictingRankingIcon />;
        case 'predicting-manager-alineaciones':
            return <AlineacionesManagersIcon iconWidth={iconWidth} iconHeight={iconHeight} />;
        case 'predicting-manager-reglas':
        case 'quiniela-reglas-del-juego':
            return <PredictingManagerRulesIcon />;
        case 'predicting-manager':
            return <PredictingManagerIcon />;
        case 'puja-fantasy':
        case 'puja-fantasy-biwenger':
            return <PujaIdealIcon />;
        case 'quiniela-administrador':
            return <LocalPlayIcon style={{ width: iconWidth, height: iconHeight }} />;
        case 'quiniela':
            return <QuinielaAnaliticaIcon iconWidth={iconWidth} iconHeight={iconHeight} />;
        case 'actividades':
            return <ActivityEventsIcon width={'25'} height={'25'} />;
        case 'quiniela-juego':
            return <VoteIcon iconWidth={iconWidth} iconHeight={iconHeight} />;
        case 'jugadores-lesionados':
            return <LesionadosIcon iconWidth={'25'} iconHeight={'25'} />;
        case 'jugadores-sancionados':
            return <SancionadosIcon />;
        case 'jugadores-dudas':
            return <DudaStatusIcon iconWidth={'25'} iconHeight={'25'} />;
        case 'jugadores-apercibidos':
            return <ApercibidosStatusIcon />;
        case 'calendar-competitions':
            return <FullCalendarIcon color={'#fff'} iconWidth={iconWidth} iconHeight={iconHeight} />;
        case 'configuration':
        case 'configuration-twitch':
            return <ConfigurationIcon />;
        case 'administrador-convocatorias':
            return <ConvocatoriasIcon />;
        case 'virus-fifa':
            return <VirusFifaIcon />;
        case 'salon-de-la-fama':
            return <SalonDeLaFamaIcon />;
        case 'quedada-fantasy':
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width={'25'} height={'25'} viewBox="0 0 48 48">
                    <path
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m14.28 22.2l4-2.2m0 0v16m6.127-1.35c1.104.926 2.297 1.35 4.975 1.35h.624a4 4 0 0 0 3.999-4h0a4 4 0 0 0-4-3.998m-5.6-6.666c1.107-.922 2.3-1.343 4.979-1.336l.622.004a4 4 0 0 1 3.999 3.999h0a4 4 0 0 1-4 3.999m-3.073 0h3.074"
                    />
                    <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
                        <rect width="35" height="36" x="6.5" y="7.5" rx="4" ry="4" />
                        <path d="M33.5 4.5v6m-19-6v6" />
                    </g>
                </svg>
            );
        case 'la-liga':
            return <InfoIcon iconWidth={iconWidth} iconHeight={iconHeight} />;
        case 'clasification-competitions':
            return <ClasificacionNavIcon />;
        case 'chollos':
            return <ChollosIcon width={iconWidth} height={iconHeight} />;
        case 'mercado-de-fichajes-en-vivo':
            return <MercadoFichajesLive />;
        case 'premios-y-sorteos':
            return <PremiosYSorteosIcon />;
        case 'cuentas-de-usuario':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={iconWidth}
                    height={iconHeight}
                    viewBox="0 0 24 24"
                >
                    <path
                        fill="#fff"
                        d="M12 11q.825 0 1.413-.588Q14 9.825 14 9t-.587-1.413Q12.825 7 12 7q-.825 0-1.412.587Q10 8.175 10 9q0 .825.588 1.412Q11.175 11 12 11Zm0 2q-1.65 0-2.825-1.175Q8 10.65 8 9q0-1.65 1.175-2.825Q10.35 5 12 5q1.65 0 2.825 1.175Q16 7.35 16 9q0 1.65-1.175 2.825Q13.65 13 12 13Zm0 11q-2.475 0-4.662-.938q-2.188-.937-3.825-2.574Q1.875 18.85.938 16.663Q0 14.475 0 12t.938-4.663q.937-2.187 2.575-3.825Q5.15 1.875 7.338.938Q9.525 0 12 0t4.663.938q2.187.937 3.825 2.574q1.637 1.638 2.574 3.825Q24 9.525 24 12t-.938 4.663q-.937 2.187-2.574 3.825q-1.638 1.637-3.825 2.574Q14.475 24 12 24Zm0-2q1.8 0 3.375-.575T18.25 19.8q-.825-.925-2.425-1.612q-1.6-.688-3.825-.688t-3.825.688q-1.6.687-2.425 1.612q1.3 1.05 2.875 1.625T12 22Zm-7.7-3.6q1.2-1.3 3.225-2.1q2.025-.8 4.475-.8q2.45 0 4.463.8q2.012.8 3.212 2.1q1.1-1.325 1.713-2.95Q22 13.825 22 12q0-2.075-.788-3.887q-.787-1.813-2.15-3.175q-1.362-1.363-3.175-2.151Q14.075 2 12 2q-2.05 0-3.875.787q-1.825.788-3.187 2.151Q3.575 6.3 2.788 8.113Q2 9.925 2 12q0 1.825.6 3.463q.6 1.637 1.7 2.937Z"
                    />
                </svg>
            );
        case 'administrador-buscador-jugadores':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={iconWidth}
                    height={iconHeight}
                    viewBox="0 0 14 14"
                >
                    <path
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M1.75 1.5a.25.25 0 0 0-.25.25v1.5a.75.75 0 0 1-1.5 0v-1.5A1.75 1.75 0 0 1 1.75 0h1.5a.75.75 0 0 1 0 1.5zm11.5 8.5a.75.75 0 0 1 .75.75v1.5A1.75 1.75 0 0 1 12.25 14h-1.5a.75.75 0 0 1 0-1.5h1.5a.25.25 0 0 0 .25-.25v-1.5a.75.75 0 0 1 .75-.75m-11.75.75a.75.75 0 0 0-1.5 0v1.5A1.75 1.75 0 0 0 1.75 14h1.5a.75.75 0 0 0 0-1.5h-1.5a.25.25 0 0 1-.25-.25zm8.5-10a.75.75 0 0 1 .75-.75h1.5A1.75 1.75 0 0 1 14 1.75v1.5a.75.75 0 0 1-1.5 0v-1.5a.25.25 0 0 0-.25-.25h-1.5A.75.75 0 0 1 10 .75M6.25 4a2.25 2.25 0 1 0 0 4.5a2.25 2.25 0 0 0 0-4.5M2.5 6.25a3.75 3.75 0 1 1 6.879 2.068L11.03 9.97a.75.75 0 1 1-1.06 1.06L8.318 9.38A3.75 3.75 0 0 1 2.5 6.25"
                        clipRule="evenodd"
                    />
                </svg>
            );
        case 'app':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={iconWidth}
                    height={iconHeight}
                    viewBox="0 0 24 24"
                >
                    <path
                        fill="currentColor"
                        d="M20.07 4.93A9.97 9.97 0 0 1 23 12c0 2.76-1.12 5.26-2.93 7.07l-1.41-1.41A7.94 7.94 0 0 0 21 12c0-2.21-.89-4.22-2.34-5.66zm-2.83 2.83A5.98 5.98 0 0 1 19 12c0 1.65-.67 3.15-1.76 4.24l-1.41-1.41A4 4 0 0 0 17 12c0-1.11-.45-2.11-1.17-2.83zM13 10a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2a2 2 0 0 1 2-2m-1.5-9A2.5 2.5 0 0 1 14 3.5V8h-2V4H3v15h9v-3h2v4.5a2.5 2.5 0 0 1-2.5 2.5h-8A2.5 2.5 0 0 1 1 20.5v-17A2.5 2.5 0 0 1 3.5 1z"
                    />
                </svg>
            );
        case 'tier-list':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={iconWidth}
                    height={iconHeight}
                    viewBox="0 0 512 512"
                >
                    <path
                        fill="#FFD469"
                        d="M450.812 462.658H74.759a8.802 8.802 0 0 1-8.802-8.802V77.802A8.802 8.802 0 0 1 74.759 69h376.215a8.64 8.64 0 0 1 8.64 8.64v376.215a8.802 8.802 0 0 1-8.802 8.803"
                    />
                    <path
                        fill="#597B91"
                        d="M393.375 168.403H126.007c-6.613 0-11.974-5.36-11.974-11.973s5.361-11.973 11.974-11.973h267.368c6.612 0 11.974 5.36 11.974 11.973s-5.362 11.973-11.974 11.973m-53.059 66.401c0-6.613-5.361-11.974-11.974-11.974H126.007c-6.613 0-11.974 5.361-11.974 11.974s5.361 11.974 11.974 11.974h202.335c6.613-.001 11.974-5.361 11.974-11.974m-85.479 78.374c0-6.612-5.361-11.974-11.974-11.974H126.007c-6.613 0-11.974 5.361-11.974 11.974s5.361 11.974 11.974 11.974h116.856c6.613-.001 11.974-5.362 11.974-11.974m101.165 78.374c0-6.612-5.361-11.974-11.974-11.974H126.007c-6.613 0-11.974 5.361-11.974 11.974s5.361 11.974 11.974 11.974h218.021c6.613-.001 11.974-5.362 11.974-11.974m40.334-78.374c0-6.612-5.361-11.974-11.974-11.974h-80.668c-6.612 0-11.974 5.361-11.974 11.974s5.361 11.974 11.974 11.974h80.668c6.613-.001 11.974-5.362 11.974-11.974"
                    />
                </svg>
            );
        case 'public-tierlist':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={iconWidth}
                    height={iconHeight}
                    viewBox="0 0 24 24"
                >
                    <g fill="none">
                        <path
                            fill="#00EBE5"
                            d="m17.312 19.347l4.1-2.053L6.706 9.953L2.6 12l14.706 7.347zM12 22l4.118-2.053l-9.412-4.706l-4.089 2.053zm9.4-10l-4.106 2.047L13.2 12l4.1-2.047z"
                        />
                        <path
                            fill="#37EB77"
                            d="M7.888 4.059L12.006 2l4.112 2.059L12 6.106L7.888 4.053zm-1.2.594l-4.1 2.053l9.412 4.7l4.1-2.047l-9.412-4.7zm10.606 4.112l4.112-2.06l-4.1-2.04l-4.118 2.053z"
                        />
                    </g>
                </svg>
            );
    }
};

export { getIcon };
