import { initialState } from '../initial-state';
import { REDUCERS } from '@/constants/reducers';
import { getFromLocalStorage, removeFromLocalStorage, addToLocalStorage } from '@/helpers/cookies';
import { SITE_USER_COOKIE } from '@/constants/site';
import { Rutas } from '@/constants/rutas';

export function user(state, action) {
    switch (action.type) {
        case REDUCERS.LOGGED_IN_USER:
            const jsonData = JSON.stringify(action.payload);
            addToLocalStorage(SITE_USER_COOKIE, jsonData, 30);
            if (action.payload?.prevUrl?.length > 2) {
                window.location.href = action.payload?.prevUrl;
            } else {
                window.location.href = Rutas.INICIO;
            }
            return { ...state, user: action.payload };
        case REDUCERS.LOGGED_OUT_USER:
            removeFromLocalStorage(SITE_USER_COOKIE);
            window.location.href = Rutas.INICIO;
            return { ...state, user: initialState };
        case REDUCERS.GET_DATA_FROM_COOKIES:
            const dataFromCookies = getFromLocalStorage(SITE_USER_COOKIE);
            const data = JSON.parse(dataFromCookies);
            const newState = data || initialState.user;
            return { ...state, user: newState };
        case REDUCERS.UPDATE_USER:
            const { user } = state;
            return {
                ...state,
                user: {
                    ...user,
                    prevUrl: user.currentUrl,
                    ...action.payload,
                },
            };
        default:
            return state;
    }
}
