export const EVENTS_ACTIONS = {
    MASTER_PLAYER: 'master_player',
    FANTASY_HISTORIC: 'fantasy-historic',
    COMPARADOR_JUGADORES: 'comparador_jugadores',
    NOTICIAS_FLASH: 'noticias_flash',
    ALINEACIONES_PROBABLES: 'alineaciones_probables',
};

export const EVENTS_CATEGORIES = {
    OPEN: 'open',
    TEAM: 'team',
    PARTIDO: 'partido',
};

export const EVENTS_LABELS = {
    CACHE: 'cache',
    API: 'api',
};

export const logEventTracker = (action, category, label) => {
    gtag('event', action, {
        app_name: 'analitica-fantasy',
        screen_name: action,
        event_category: category ?? EVENTS_CATEGORIES.OPEN,
        event_label: label ?? EVENTS_LABELS.API,
    });
};
